import { EVENTS } from '@repo/shared-types';
import {
  assign,
  enqueueActions,
  fromCallback,
  fromPromise,
  raise,
  setup,
  sendParent,
} from 'xstate';

import { supportsPassive, getEnv, debounce } from '@repo/utils';

import {
  AnyAutomaticRefreshEvent,
  AnyUserActivityEvent,
  AutomaticRefreshGuardArgs,
  REFRESH_GUARDS,
  RefreshMachineContext,
  REFRESH_EVENTS,
  REFRESH_STATES,
  USER_ACTIVITY_EVENTS,
} from '@repo/shared-types';
import { TriggerAutomaticRefreshEvent } from '@repo/shared-types/src/types/state/events.types';

const userActivityMachine = fromCallback<AnyUserActivityEvent>(({ sendBack }) => {
  const env = getEnv();

  const sendMouseMoveEvent = debounce(
    (timestamp: number) =>
      sendBack({
        type: USER_ACTIVITY_EVENTS.MOUSE_MOVE,
        data: timestamp,
      }),
    100,
  );
  const sendScrollEvent = debounce(
    (timestamp: number) =>
      sendBack({
        type: USER_ACTIVITY_EVENTS.SCROLL,
        data: timestamp,
      }),
    100,
  );

  env.document.addEventListener(
    'visibilitychange',
    () => {
      sendBack({
        type: USER_ACTIVITY_EVENTS.DOCUMENT_VISIBILITY,
        data: !document.hidden,
      });
    },
    false,
  );

  env.addEventListener('mousemove', (): void => {
    sendMouseMoveEvent(Date.now());
  });

  env.addEventListener(
    'scroll',
    (): void => {
      sendScrollEvent(Date.now());
    },
    supportsPassive ? { passive: true } : false,
  );
});

const featureEnabled = ({ context }: AutomaticRefreshGuardArgs): boolean => {
  if (context.isRoadblocked) return false;
  if (context.refreshPaused) return false;

  return context.featureEnabled;
};
const userActiveRecently = ({ context }: AutomaticRefreshGuardArgs): boolean => {
  if (!context.documentVisible) return false;
  const timestamp = Date.now();
  if (timestamp - context.mouseMoved < context.activityTimeout) return true;
  if (timestamp - context.scrolled < context.activityTimeout) return true;
  return false;
};

const refreshMachine = setup({
  actors: {
    userActivityMachine,
    wait: fromPromise<
      void,
      {
        updateInterval: number;
      }
    >(({ input }) => new Promise(resolve => setTimeout(resolve, input.updateInterval))),
  },
  types: {} as {
    context: RefreshMachineContext;
    events: AnyAutomaticRefreshEvent;
  },
  guards: {
    [REFRESH_GUARDS.USER_ACTIVE_RECENTLY]: userActiveRecently,
    [REFRESH_GUARDS.FEATURE_DISABLED]: actionArgs => !featureEnabled(actionArgs),
    [REFRESH_GUARDS.FEATURE_ENABLED]: featureEnabled,
  },
}).createMachine({
  context: {
    activityTimeout: 60000,
    updateInterval: 1000,

    documentVisible: true,
    mouseMoved: 0,
    scrolled: 0,

    refreshPaused: false,
    isRoadblocked: false,
    featureEnabled: true,

    userActivityMachine: {} as RefreshMachineContext['userActivityMachine'],
  },
  initial: REFRESH_STATES.SETUP,
  states: {
    [REFRESH_STATES.SETUP]: {
      entry: assign({
        userActivityMachine: ({ spawn }) =>
          spawn('userActivityMachine', { id: 'userActivityMachine' }),
      }),
      always: [
        {
          guard: REFRESH_GUARDS.FEATURE_ENABLED,
          target: REFRESH_STATES.WAIT,
        },
        { target: REFRESH_STATES.STOP },
      ],
    },
    [REFRESH_STATES.WAIT]: {
      invoke: {
        src: 'wait',
        input: ({ context }) => ({ updateInterval: context.updateInterval }),
        onDone: REFRESH_STATES.UPDATE,
      },
      on: {
        [REFRESH_EVENTS.CHECK]: {
          guard: REFRESH_GUARDS.FEATURE_DISABLED,
          target: REFRESH_STATES.STOP,
        },
      },
    },
    [REFRESH_STATES.UPDATE]: {
      always: [
        {
          guard: REFRESH_GUARDS.FEATURE_ENABLED,
          actions: enqueueActions(({ check, enqueue }) => {
            if (check(REFRESH_GUARDS.USER_ACTIVE_RECENTLY))
              enqueue(
                sendParent({
                  type: EVENTS.TRIGGER_AUTOMATIC_REFRESH,
                } as TriggerAutomaticRefreshEvent),
              );
          }),
          target: REFRESH_STATES.WAIT,
        },
        { target: REFRESH_STATES.STOP },
      ],
    },
    [REFRESH_STATES.STOP]: {
      on: {
        [REFRESH_EVENTS.CHECK]: {
          guard: REFRESH_GUARDS.FEATURE_ENABLED,
          target: REFRESH_STATES.WAIT,
        },
      },
    },
  },
  on: {
    [USER_ACTIVITY_EVENTS.DOCUMENT_VISIBILITY]: {
      actions: assign({
        documentVisible: ({ event }) => event.data,
      }),
    },
    [USER_ACTIVITY_EVENTS.MOUSE_MOVE]: {
      actions: assign({
        mouseMoved: ({ event }) => event.data,
      }),
    },
    [USER_ACTIVITY_EVENTS.SCROLL]: {
      actions: assign({
        scrolled: ({ event }) => event.data,
      }),
    },
    [REFRESH_EVENTS.SET_ROADBLOCK]: {
      actions: [
        assign({
          isRoadblocked: ({ event }) => event.data,
        }),
        raise({
          type: REFRESH_EVENTS.CHECK,
        }),
      ],
    },
    [REFRESH_EVENTS.SET_REFRESH_PAUSED]: {
      actions: [
        assign({
          refreshPaused: ({ event }) => event.data,
        }),
        raise({
          type: REFRESH_EVENTS.CHECK,
        }),
      ],
    },
    [REFRESH_EVENTS.SET_FEATURE_ENABLED]: {
      actions: [
        assign({
          featureEnabled: ({ event }) => event.data,
        }),
        raise({
          type: REFRESH_EVENTS.CHECK,
        }),
      ],
    },
  },
});

export default refreshMachine;
