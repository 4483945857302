import { HybridFeatures } from './index.types';

export const defaultAdRefreshBlockingOrderIds = [
  2513339405, // Just Premium
  2514167739, // Just Premium
  2874872801, // (PT) Teads Mobile UK 2021
  2877427432, // (PT) Teads Mobile US 2021 v2
  2877429796, // (PT) Teads Mobile AU 2021
  2877886512, // (PT) Teads AMP UK (exc TI legacy sites)
  2877887418, // (PT) Teads AMP US (exc TI legacy sites)
  2877887466, // (PT) Teads AMP AU (exc TI legacy sites)
  2827762776,
  2826996473,
  2880357734,
  2880588673,
];

export const defaultAdRefreshBlockingLineItemIds = [
  6008291945, // Sandbox Unrefreshable Test
  6193492557,
  6194229978,
  6195127879,
  6195136687,
  6195678233,
  6195879766,
  6197440511,
  6201494390,
  6218805500,
  6219063790,
  6228853125,
  6229323721,
  6231351575,
  6233147912,
  6233860734,
  6236343782,
  6236635638,
  6236635641,
  6236635644,
  6239110052,
  6239111681,
  6240915032,
  6242875605,
  6182774154,
  6184983290,
  6212226609,
  6222591089,
  6224258042,
  6228151608,
  6229961449,
  6232458621,
  6233809629,
  6233811099,
  6239027171,
  6239598945,
  6244643908,
  6246533979,
  6247702719,
  6248348359,
  6255869480,
  6259394785,
  6259614460,
  6259614466,
  6259614469,
  6259614475,
  6259614604,
  6260065412,
  6260273939,
  6261710619,
  6264175823,
  6264176096,
  6264775824,
  6264956157,
  6265383334,
  6265688544,
  6266812215,
  6266887600,
  6268238786,
  6268238789,
  6268629727,
  6269229728,
  6274635296,
  6276386594,
  6280548848,
  6283941831,
  6284386725,
  6287544407,
  6291356351,
  6292073977,
  6292883469,
];

export const defaultStickyVideoBlockingLineItemIds = [
  6242029588, 6295299526, 6284102151, 6282675481, 6278934436, 6289116686, 6278934439, 6242029588,
  6242042542, 6293275833, 6276397380,
];

export const defaultHideableAnchoredBlockingLineItemIds: number[] = [];

export const FALLBACK_HYBRID_FEATURES: HybridFeatures = {
  customActivations: {
    AD_REFRESH_DISABLED: {
      LINE_ITEM: defaultAdRefreshBlockingLineItemIds,
      ORDER: defaultAdRefreshBlockingOrderIds,
      ADVERTISER: [],
    },
    VIDEO_STICKY_AUTOPLAY_DISABLED: {
      LINE_ITEM: defaultStickyVideoBlockingLineItemIds,
    },
    HIDEABLE_ANCHORED_ENABLED: {
      LINE_ITEM: defaultHideableAnchoredBlockingLineItemIds,
    },
    FULL_PAGE_TAKEOVER: {
      LINE_ITEM: defaultAdRefreshBlockingLineItemIds,
    },
    ROADBLOCK: {
      LINE_ITEM: defaultAdRefreshBlockingLineItemIds,
    }
  }
};
