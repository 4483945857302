import { Ad, AdUnitMode, DataObject, REPORT_AUCTION, Targeting } from '@repo/shared-types';
import { ExtendedGoogletag, getEnv, log, replaceAmpersand, sentry, timeData } from '@repo/utils';
import {
  ActorRefFromLogic,
  AnyActorRef,
  AnyEventObject,
  assign,
  CallbackActorLogic,
  enqueueActions,
  EventObject,
  fromCallback,
  fromPromise,
  MetaObject,
  NonReducibleUnknown,
  ParameterizedObject,
  ProvidedActor,
  sendParent,
  setup,
  stateIn,
  StateMachine,
  StateSchema,
} from 'xstate';
import {
  AD_MANAGER_EVENTS_IN,
  AD_MANAGER_EVENTS_OUT,
  AnyAdManagerEvent,
  GOOGLETAG_LISTENER_EVENTS,
  GoogletagSlotImpressionViewableEvent,
  GoogletagSlotOnLoadEvent,
  GoogletagSlotRenderEndedEvent,
  GoogletagSlotVisibilityChangedEvent,
  SlotImpressionViewableEvent,
  SlotOnLoadEvent,
  SlotRenderEndedEvent,
  SlotVisibilityChangedEvent,
} from '@repo/shared-types/src/types/ad-framework/events.types';
import { EMPTY_OUTPUT } from '@repo/shared-types/src/types/ad-framework/empty-output';
import metrics from 'metrics/index';

export enum ACTIONS {
  CONFIGURE = 'CONFIGURE',
  ENABLE = 'ENABLE',
  LISTEN = 'LISTEN',
  GET_GOOGLETAG = 'GET_GOOGLETAG',
  DESTROY_ADS = 'DESTROY_ADS',
  CREATE_AD = 'CREATE_AD',
  ADD_AUCTION_TO_STACK = 'ADD_AUCTION_TO_STACK',
  SET_TARGETING = 'SET_TARGETING',
  REFRESH_AUCTION = 'REFRESH_AUCTION',
}
export enum STATES {
  CHECK_REQUIREMENTS = 'CHECK_REQUIREMENTS',
  CONFIGURATION = 'CONFIGURATION',
  ERROR = 'ERROR',
  WAIT_FOR_AUCTION = 'WAIT_FOR_AUCTION',
  AUCTION = 'AUCTION',
}
export enum GUARDS {
  GOOGLETAG_EXISTS = 'GOOGLETAG_EXISTS',
  AUCTIONS_IN_STACK = 'AUCTIONS_IN_STACK',
}

export interface AdManagerMachineContext {
  googletag: googletag.Googletag;
  googletagListener: GoogletagListenerActor;
  gptSlots: Record<string, googletag.Slot>;
  auctionId: number;
  auctionStack: Array<Array<DataObject<Ad>>>;
  auctions: Record<
    string,
    {
      ads: Array<DataObject<Ad>>;
      id: number;
      ended: boolean;
    }
  >;
  error: string | null;
}
export interface AdManagerMachineInput {
  privacySettings?: {
    restrictDataProcessing?: boolean;
  };
  publisherProvidedId?: string;
}

export type AdManagerMachineDefinition = StateMachine<
  AdManagerMachineContext & AdManagerMachineInput,
  AnyAdManagerEvent,
  Record<string, AnyActorRef | undefined>,
  ProvidedActor,
  ParameterizedObject,
  ParameterizedObject,
  string,
  STATES,
  string,
  AdManagerMachineInput,
  NonReducibleUnknown,
  EventObject,
  MetaObject,
  StateSchema
>;

const defineGPTSlot = (
  { googletag, auctionId }: AdManagerMachineContext,
  ad: DataObject<Ad>,
): googletag.Slot | null => {
  const id = ad.getProperty('id');
  const targeting = ad.getProperty('targeting');
  const mode = ad.getProperty('mode');
  const name = ad.getProperty('name');
  const adUnitPath = ad.getProperty('adUnitPath');
  const isOutOfPage = mode === AdUnitMode.OOP;
  const isInterstitial = mode === AdUnitMode.INTERSTITIAL;
  const sizes = isOutOfPage ? [[1, 1]] : ad.getProperty('sizes');

  let gptSlot: googletag.Slot | null = null;
  try {
    if (isOutOfPage) {
      gptSlot = googletag.defineOutOfPageSlot(adUnitPath, id);
    } else if (isInterstitial) {
      gptSlot = googletag.defineOutOfPageSlot(
        adUnitPath,
        (googletag as ExtendedGoogletag).enums.OutOfPageFormat.INTERSTITIAL,
      );
    } else {
      if (sizes === undefined) throw new Error('Error defining GPT slot, sizes are undefined.');
      gptSlot = googletag.defineSlot(adUnitPath, sizes, id);
    }
  } catch (error) {
    if (error instanceof Error) log.error(`Error defining GPT slot. ${error.toString()}`);
    return null;
  }

  if (gptSlot === null) {
    log.error(`Unable to define GPT slot. advert: ${name}, adUnitPath: ${adUnitPath}`);
    return null;
  }

  Object.keys(targeting).forEach(key => {
    if (gptSlot !== null) {
      gptSlot.setTargeting(key, targeting[key]);
    }
  });
  gptSlot.addService(googletag.pubads());

  if (!isInterstitial) {
    googletag.display(id);
  }

  gptSlot.setTargeting('auctionId', auctionId.toString());

  return gptSlot;
};

const googletagListener = fromCallback<
  AnyEventObject,
  { googletag: googletag.Googletag },
  AnyAdManagerEvent
>(({ input: { googletag }, sendBack }) => {
  const handleSlotVisibilityChanged = (
    event: googletag.events.SlotVisibilityChangedEvent,
  ): void => {
    const adId = event.slot.getSlotElementId();
    sendBack({
      type: GOOGLETAG_LISTENER_EVENTS.SLOT_VISIBILITY_CHANGED,
      data: { adId, inViewPercentage: event.inViewPercentage },
    } as GoogletagSlotVisibilityChangedEvent);
  };

  const handleSlotRenderEnded = (event: googletag.events.SlotRenderEndedEvent): void => {
    const adId = event.slot.getSlotElementId();
    const gptOutput = event.isEmpty
      ? EMPTY_OUTPUT
      : {
          isEmpty: event.isEmpty,
          size: event.size,
          advertiser: event.advertiserId || -1,
          campaign: event.campaignId || -1,
          lineItem: event.lineItemId || -1,
          creative: event.creativeId || -1,
          creativeTemplate: '',
          gptSlot: event.slot,
        };
    sendBack({
      type: GOOGLETAG_LISTENER_EVENTS.SLOT_RENDER_ENDED,
      data: { adId, gptOutput },
    } as GoogletagSlotRenderEndedEvent);
  };

  const handleSlotOnLoad = (event: googletag.events.SlotOnloadEvent): void => {
    const adId = event.slot.getSlotElementId();
    sendBack({
      type: GOOGLETAG_LISTENER_EVENTS.SLOT_ON_LOAD,
      data: { adId },
    } as GoogletagSlotOnLoadEvent);
  };

  const handleImpressionViewable = (event: googletag.events.ImpressionViewableEvent): void => {
    const adId = event.slot.getSlotElementId();
    sendBack({
      type: GOOGLETAG_LISTENER_EVENTS.SLOT_IMPRESSION_VIEWABLE,
      data: { adId },
    } as GoogletagSlotImpressionViewableEvent);
  };

  googletag.pubads().addEventListener('slotVisibilityChanged', handleSlotVisibilityChanged);
  googletag.pubads().addEventListener('slotRenderEnded', handleSlotRenderEnded);
  googletag.pubads().addEventListener('slotOnload', handleSlotOnLoad);
  googletag.pubads().addEventListener('impressionViewable', handleImpressionViewable);
});

export type GoogletagListenerActor = ActorRefFromLogic<
  CallbackActorLogic<
    AnyEventObject,
    {
      googletag: googletag.Googletag;
    },
    AnyAdManagerEvent
  >
>;

const waitForGoogleTag = fromPromise<void, void>(async () => {
  const env = getEnv();
  const { googletag } = env;
  if (!googletag) return;
  if (!googletag.cmd) return;

  return new Promise(resolve => {
    googletag.cmd.push(resolve);
  });
});

const adManagerMachine: AdManagerMachineDefinition = setup({
  types: {
    context: {} as AdManagerMachineContext & AdManagerMachineInput,
    events: {} as AnyAdManagerEvent,
    input: {} as AdManagerMachineInput,
  },
  actors: {
    waitForGoogleTag,
    googletagListener,
  },
  actions: {
    [ACTIONS.GET_GOOGLETAG]: assign({
      googletag: () => {
        const env = getEnv();
        const { googletag } = env;
        return googletag!;
      },
    }),
    [ACTIONS.ENABLE]: ({ context: { googletag } }) => {
      googletag.enableServices();
    },
    [ACTIONS.CONFIGURE]: ({ context: { googletag, privacySettings, publisherProvidedId } }) => {
      googletag.pubads().enableAsyncRendering();
      googletag.pubads().collapseEmptyDivs(true);
      googletag.pubads().enableSingleRequest();
      googletag.pubads().disableInitialLoad();
      googletag.pubads().setCentering(true);

      googletag.setAdIframeTitle('Advertisement');

      if (privacySettings) googletag.pubads().setPrivacySettings(privacySettings);

      if (publisherProvidedId) googletag.pubads().setPublisherProvidedId(publisherProvidedId);
    },
    [ACTIONS.LISTEN]: assign({
      googletagListener: ({ context: { googletag }, spawn }) =>
        spawn('googletagListener', {
          input: { googletag },
        }),
    }),
    [ACTIONS.DESTROY_ADS]: (
      { context: { googletag, gptSlots } },
      { advertIds }: { advertIds: Array<string> },
    ) => {
      advertIds.forEach(advertId => {
        const slot = gptSlots[advertId];
        if (slot === undefined) {
          return;
        }

        // This should return true/false but the Index wrapped version returns undefined
        // Once this is fixed we can check if the destroy succeeded
        googletag.destroySlots([slot]);
      });
    },

    [ACTIONS.CREATE_AD]: assign({
      gptSlots: ({ context }, { ad }: { ad: DataObject<Ad> }) => {
        const adID = ad.getProperty('id');
        const gptSlot = defineGPTSlot(context, ad);
        if (!gptSlot) return context.gptSlots;
        return {
          ...context.gptSlots,
          [adID]: gptSlot,
        };
      },
    }),
    [ACTIONS.ADD_AUCTION_TO_STACK]: assign({
      auctionStack: ({ context: { auctionStack } }, { ads }: { ads: Array<DataObject<Ad>> }) => [
        ...auctionStack,
        ads,
      ],
    }),
    [ACTIONS.SET_TARGETING]: ({ context: { googletag } }, targeting: Targeting) => {
      Object.keys(replaceAmpersand(targeting)).forEach(key => {
        googletag.pubads().setTargeting(key, targeting[key]);
      });
    },
    [ACTIONS.REFRESH_AUCTION]: (
      { context: { gptSlots, auctions, googletag } },
      auctionId: number,
    ) => {
      const { ads } = auctions[auctionId];
      const refreshSlots = ads
        .map(ad => {
          const adID = ad.getProperty('id');
          return gptSlots[adID];
        })
        .filter((value): value is googletag.Slot => value !== undefined);
      googletag.pubads().refresh(refreshSlots, { changeCorrelator: false });
    },
  },
  guards: {
    [GUARDS.GOOGLETAG_EXISTS]: () => {
      const env = getEnv();
      const { googletag } = env;
      return Boolean(googletag);
    },
    [GUARDS.AUCTIONS_IN_STACK]: ({ context: { auctionStack } }) => auctionStack.length !== 0,
  },
}).createMachine({
  context: ({ input }) => ({
    googletag: {} as googletag.Googletag,
    googletagListener: {} as GoogletagListenerActor,
    gptSlots: {},
    auctionId: 0,
    auctionStack: [],
    auctions: {},
    error: null,
    ...input,
  }),
  initial: STATES.CHECK_REQUIREMENTS,
  states: {
    [STATES.CHECK_REQUIREMENTS]: {
      invoke: {
        src: 'waitForGoogleTag',
        onDone: [
          {
            guard: GUARDS.GOOGLETAG_EXISTS,
            actions: ACTIONS.GET_GOOGLETAG,
            target: STATES.CONFIGURATION,
          },
          {
            actions: assign({ error: 'googletag is unavailable, unable to initialise.' }),
            target: STATES.ERROR,
          },
        ],
      },
    },
    [STATES.CONFIGURATION]: {
      always: {
        actions: [ACTIONS.CONFIGURE, ACTIONS.LISTEN, ACTIONS.ENABLE],
        target: STATES.WAIT_FOR_AUCTION,
      },
    },
    [STATES.WAIT_FOR_AUCTION]: {
      always: {
        guard: GUARDS.AUCTIONS_IN_STACK,
        target: STATES.AUCTION,
      },
    },
    [STATES.AUCTION]: {
      always: {
        actions: [
          assign({
            auctionId: ({ context: { auctionId } }) => auctionId + 1,
          }),
          assign({
            auctions: ({
              context: {
                auctions,
                auctionId,
                auctionStack: [ads],
              },
            }) => ({
              ...auctions,
              [auctionId]: {
                id: auctionId,
                ads,
                ended: false,
              },
            }),
          }),
          sendParent(
            ({
              context: {
                auctionId,
                auctionStack: [ads],
              },
            }) => ({
              type: REPORT_AUCTION.START,
              data: {
                time: timeData(),
                auction: auctionId,
                adNames: ads.map(ad => ad.getProperty('name')),
              },
            }),
          ),
          ({
            context: {
              auctionId,
              auctionStack: [ads],
            },
          }) => {
            ads.forEach(ad => {
              ad.update({
                auctionId,
                fetchTime: getEnv().performance.now(),
              });
            });
          },
          enqueueActions(
            ({
              enqueue,
              context: {
                auctionStack: [ads],
              },
            }) => {
              ads.forEach(ad => {
                enqueue({
                  type: ACTIONS.CREATE_AD,
                  params: { ad },
                });
              });
            },
          ),
          sendParent(
            ({
              context: {
                auctionId,
                auctionStack: [ads],
              },
            }) => ({
              type: AD_MANAGER_EVENTS_OUT.AUCTION_CREATED,
              data: {
                auctionId,
                ads,
              },
            }),
          ),
          assign({
            auctionStack: ({ context: { auctionStack } }) => auctionStack.slice(1),
          }),
        ],
        target: STATES.WAIT_FOR_AUCTION,
      },
    },
    [STATES.ERROR]: {
      entry: ({ context: { error } }) => {
        log.error(error || 'Error occurred in ad manager.');
      },
    },
  },
  on: {
    [AD_MANAGER_EVENTS_IN.AUCTION_PROCESSED]: {
      actions: [
        {
          type: ACTIONS.REFRESH_AUCTION,
          params: ({
            event: {
              data: { auctionId },
            },
          }) => auctionId,
        },
        assign({
          auctions: ({
            context: { auctions },
            event: {
              data: { auctionId },
            },
          }) => ({
            ...auctions,
            [auctionId]: {
              ...auctions[auctionId],
              ended: true,
            },
          }),
        }),
      ],
    },
    [AD_MANAGER_EVENTS_IN.SET_TARGETING]: {
      actions: {
        type: ACTIONS.SET_TARGETING,
        params: ({
          event: {
            data: { targeting },
          },
        }) => targeting,
      },
    },
    [AD_MANAGER_EVENTS_IN.AUCTION]: [
      {
        guard: stateIn(STATES.WAIT_FOR_AUCTION),
        actions: {
          type: ACTIONS.ADD_AUCTION_TO_STACK,
          params: ({
            event: {
              data: { ads },
            },
          }) => ({ ads }),
        },
        target: `.${STATES.AUCTION}`,
      },
      {
        actions: {
          type: ACTIONS.ADD_AUCTION_TO_STACK,
          params: ({
            event: {
              data: { ads },
            },
          }) => ({ ads }),
        },
      },
    ],
    [AD_MANAGER_EVENTS_IN.REFRESH]: [
      {
        guard: ({
          event: {
            data: { ads },
          },
        }) => ads.length === 0,
        actions: () => {
          log.error('Called GAM API refresh with no ads.');
        },
      },
      {
        actions: [
          ({
            event: {
              data: { ads },
            },
          }) => {
            const adNames = ads.map(ad => ad.getProperty('name'));
            sentry.breadcrumb({
              category: 'script',
              message: `GAM API refresh - ${adNames}`,
            });
            metrics.mark(`GAM API refresh - ${adNames}`);
          },
          {
            type: ACTIONS.DESTROY_ADS,
            params: ({
              event: {
                data: { ads },
              },
            }) => ({ advertIds: ads.map(ad => ad.getProperty('id')) }),
          },
          {
            type: ACTIONS.ADD_AUCTION_TO_STACK,
            params: ({
              event: {
                data: { ads },
              },
            }) => ({ ads }),
          },
        ],
      },
    ],
    [GOOGLETAG_LISTENER_EVENTS.SLOT_VISIBILITY_CHANGED]: {
      actions: sendParent(
        ({ event: { data } }) =>
          ({
            type: AD_MANAGER_EVENTS_OUT.SLOT_VISIBILITY_CHANGED,
            data,
          }) as SlotVisibilityChangedEvent,
      ),
    },
    [GOOGLETAG_LISTENER_EVENTS.SLOT_RENDER_ENDED]: {
      actions: sendParent(
        ({ event: { data } }) =>
          ({
            type: AD_MANAGER_EVENTS_OUT.SLOT_RENDER_ENDED,
            data,
          }) as SlotRenderEndedEvent,
      ),
    },
    [GOOGLETAG_LISTENER_EVENTS.SLOT_ON_LOAD]: {
      actions: sendParent(
        ({ event: { data } }) =>
          ({
            type: AD_MANAGER_EVENTS_OUT.SLOT_ON_LOAD,
            data,
          }) as SlotOnLoadEvent,
      ),
    },
    [GOOGLETAG_LISTENER_EVENTS.SLOT_IMPRESSION_VIEWABLE]: {
      actions: sendParent(
        ({ event: { data } }) =>
          ({
            type: AD_MANAGER_EVENTS_OUT.SLOT_IMPRESSION_VIEWABLE,
            data,
          }) as SlotImpressionViewableEvent,
      ),
    },
  },
});

export default adManagerMachine;
