import { EventObject } from "xstate";
import { GPTOutput } from "./ad/gpt.types";
import DataObject from "../state/data-object";
import { Ad } from "./ad/index.types";
import { Targeting } from "./targeting/index.types";
import { EMPTY_OUTPUT } from "./empty-output";

export enum GOOGLETAG_LISTENER_EVENTS {
  SLOT_VISIBILITY_CHANGED = 'SLOT_VISIBILITY_CHANGED',
  SLOT_RENDER_ENDED = 'SLOT_RENDER_ENDED',
  SLOT_ON_LOAD = 'SLOT_ON_LOAD',
  SLOT_IMPRESSION_VIEWABLE = 'SLOT_IMPRESSION_VIEWABLE',
}

export interface GoogletagSlotVisibilityChangedEvent extends EventObject {
  type: GOOGLETAG_LISTENER_EVENTS.SLOT_VISIBILITY_CHANGED;
  data: {
    adId: string;
    inViewPercentage: number;
  };
}

export interface GoogletagSlotRenderEndedEvent extends EventObject {
  type: GOOGLETAG_LISTENER_EVENTS.SLOT_RENDER_ENDED;
  data: {
    adId: string;
    gptOutput: GPTOutput | typeof EMPTY_OUTPUT;
  };
}

export interface GoogletagSlotOnLoadEvent extends EventObject {
  type: GOOGLETAG_LISTENER_EVENTS.SLOT_ON_LOAD;
  data: {
    adId: string;
  };
}

export interface GoogletagSlotImpressionViewableEvent extends EventObject {
  type: GOOGLETAG_LISTENER_EVENTS.SLOT_IMPRESSION_VIEWABLE;
  data: {
    adId: string;
  };
}

export enum AD_MANAGER_EVENTS_IN {
  AUCTION = 'AUCTION',
  REFRESH = 'REFRESH',
  SET_TARGETING = 'SET_TARGETING',
  AUCTION_PROCESSED = 'AUCTION_PROCESSED',
}

export interface AuctionEvent extends EventObject {
  type: AD_MANAGER_EVENTS_IN.AUCTION;
  data: {
    ads: Array<DataObject<Ad>>;
  };
}
export interface RefreshEvent extends EventObject {
  type: AD_MANAGER_EVENTS_IN.REFRESH;
  data: {
    ads: Array<DataObject<Ad>>;
  };
}
export interface SetTargetingEvent extends EventObject {
  type: AD_MANAGER_EVENTS_IN.SET_TARGETING;
  data: {
    targeting: Targeting;
  };
}
export interface AuctionProcessedEvent extends EventObject {
  type: AD_MANAGER_EVENTS_IN.AUCTION_PROCESSED;
  data: {
    auctionId: number;
  };
}

export type AnyAdManagerEvent =
  | GoogletagSlotVisibilityChangedEvent
  | GoogletagSlotRenderEndedEvent
  | GoogletagSlotOnLoadEvent
  | GoogletagSlotImpressionViewableEvent
  | AuctionEvent
  | RefreshEvent
  | AuctionProcessedEvent
  | SetTargetingEvent;

export enum AD_MANAGER_EVENTS_OUT {
  AUCTION_CREATED = 'AUCTION_CREATED',
  SLOT_VISIBILITY_CHANGED = 'SLOT_VISIBILITY_CHANGED',
  SLOT_RENDER_ENDED = 'SLOT_RENDER_ENDED',
  SLOT_ON_LOAD = 'SLOT_ON_LOAD',
  SLOT_IMPRESSION_VIEWABLE = 'SLOT_IMPRESSION_VIEWABLE',
}
export interface AuctionCreatedEvent extends EventObject {
  type: AD_MANAGER_EVENTS_OUT.AUCTION_CREATED;
  data: {
    auctionId: number;
    ads: Array<DataObject<Ad>>;
  };
}
export interface SlotVisibilityChangedEvent extends EventObject {
  type: AD_MANAGER_EVENTS_OUT.SLOT_VISIBILITY_CHANGED;
  data: {
    adId: string;
    inViewPercentage: number;
  };
}
export interface SlotRenderEndedEvent extends EventObject {
  type: AD_MANAGER_EVENTS_OUT.SLOT_RENDER_ENDED;
  data: {
    adId: string;
    gptOutput: GPTOutput | typeof EMPTY_OUTPUT;
  };
}
export interface SlotOnLoadEvent extends EventObject {
  type: AD_MANAGER_EVENTS_OUT.SLOT_ON_LOAD;
  data: {
    adId: string;
  };
}
export interface SlotImpressionViewableEvent extends EventObject {
  type: AD_MANAGER_EVENTS_OUT.SLOT_IMPRESSION_VIEWABLE;
  data: {
    adId: string;
  };
}

export type AnyAdManagerOutEvent =
  | AuctionCreatedEvent
  | SlotVisibilityChangedEvent
  | SlotRenderEndedEvent
  | SlotOnLoadEvent
  | SlotImpressionViewableEvent;
