import {
  AdditionalAvoidance,
  Slot,
  FEATURE,
  DataObject,
  EVENTS as BDX_EVENTS,
  SLOTIFY_ACTIONS,
  AD_AFFINITY_FAILED_REASONS,
  AnySlotifyEvent,
  SLOTIFY_EVENTS,
  SLOTIFY_GUARDS,
  INCREMENTAL_ADS_EVENTS_IN,
  STANDARD_ADS_EVENTS_IN,
  SLOTIFY_STATES,
  SlotInViewEvent,
  SlotifyMachineContext,
  CompanionBounds,
  Ad,
  AdDefinition,
  BordeauxMachineContext,
  AdUnitMode,
  Targeting,
  SlotLabelPosition,
  SlotDefinition,
} from '@repo/shared-types';
import {
  applyStyles,
  getEnv,
  getOnMountSlotStyles,
  log,
  querySelector,
  stringToStyle,
} from '@repo/utils';
import { querySelectorAll } from '@repo/utils';
import {
  AnyActorRef,
  EventObject,
  assign,
  enqueueActions,
  fromCallback,
  fromPromise,
  not,
  raise,
  sendParent,
  sendTo,
  setup,
  stateIn,
} from 'xstate';
import positionElement from 'ad-framework/slot/position-element';
import { fastdom } from '@repo/utils';
import replaceConstantsInSlot from 'ad-framework/slot/replace-constants';
import { overlapsExclusionZones, watchExclusionZones } from './exclusion';
import SlotObserver from './observer';
import staticSlotGenerator from './generator/static-slots';
import dynamicSlotGenerator from './generator/dynamic-slots';
import affinityAdGenerator from './generator/affinity-ads';
import {
  AdMatch,
  SlotifyMachineDefinition,
} from '@repo/shared-types/src/types/slotify/index.types';
import findBestAdForSlot from './find-best-ad-for-slot';
import { passesAdditionalAvoidance } from './avoidance';
import autoIncrementTargeting from 'ad-framework/ad/auto-increment-targeting';
import createAd from 'ad-framework/ad';
import createSlotifyMarkup from 'ad-framework/ad/handle-create/slotify';
import createAnchoredMarkup from 'ad-framework/ad/handle-create/anchored';
import createOutOfPageMarkup from 'ad-framework/ad/handle-create/out-of-page';
import createSkyscraperMarkup from 'ad-framework/ad/handle-create/skyscraper';
import { createSlotLabel, LABEL_STYLE } from 'ad-framework/ad/create-ad-label';
import { isEmpty } from 'ramda';
import { adsLoaded } from 'state/report';
import { reorderAds } from './actions/reorderAds';

const addAdditionalWatchers = (slot: DataObject<Slot>): void => {
  const additionalAvoidance = slot.getProperty('additionalAvoidance');

  additionalAvoidance.forEach((additionalAvoidanceConfig: AdditionalAvoidance) => {
    const elementsToAvoid = querySelectorAll<HTMLElement>(additionalAvoidanceConfig.hook);
    elementsToAvoid.forEach(element => {
      additionalAvoidanceConfig.elements.push(element);
    });
  });
};

const slotWatcher = fromCallback<
  EventObject,
  {
    slot: DataObject<Slot>;
    activationDistance: number;
  }
>(({ input: { slot, activationDistance }, sendBack }) => {
  const element = slot.getProperty('element');

  const slotBuffer =
    slot.getProperty('genericName') === 'sponsored'
      ? slot.getProperty('sponsoredSlotActivationDistanceOverride') || activationDistance
      : activationDistance;

  const slotName = slot.getProperty('name');

  const slotObserver = new SlotObserver(element, slotName, slotBuffer);

  slotObserver.observe(event => {
    if (event.isIntersecting && event.scrollPosition !== -1) {
      sendBack({
        type: SLOTIFY_EVENTS.SLOT_IN_VIEW,
        data: slot,
      } as SlotInViewEvent);
    }
  });
});

const waitForSlotsReady = fromPromise<void, { slots: Array<DataObject<Slot>> }>(
  async ({ input }) => {
    await Promise.all(input.slots.map(slot => slot.getProperty('readyPromise')));
  },
);

const matchSlot = fromPromise<
  null | Array<AdMatch>,
  { slot: DataObject<Slot> } & Pick<
    SlotifyMachineContext,
    | 'slots'
    | 'overrideCompanionBounds'
    | 'config'
    | 'isRoadblock'
    | 'avoidanceDistance'
    | 'roadblockIncrementals'
    | 'adUnits'
    | 'ads'
  >
>(async ({ input }) => {
  const { slot, slots, overrideCompanionBounds, config } = input;

  const masterName = slot.getProperty('genericName');
  const companionDefinitions = config.placement.slots.static.filter(
    slotDefinition => slotDefinition.master === masterName,
  );
  return fastdom.measure(() => {
    if (companionDefinitions.length) {
      const potentialCompanions = slots
        .getValues()
        .filter(slot => slot.getProperty('master') === masterName && !slot.getProperty('masterID'));
      const companionSets = companionDefinitions.map(slotDefinition =>
        potentialCompanions.filter(
          companion => companion.getProperty('genericName') === slotDefinition.name,
        ),
      );

      const comanionsAvailable = companionSets.every(companionSet => companionSet.length > 0);
      if (comanionsAvailable) {
        const env = getEnv();
        const defaultPageBounds: CompanionBounds = { above: 1, below: 800 };
        const pageBounds: CompanionBounds = {
          ...defaultPageBounds,
          ...overrideCompanionBounds,
          ...slot.getProperty('companionBounds'),
        };

        const bounds = slot.getProperty('element').getBoundingClientRect();
        const lowerBound = pageBounds.below === 'screenheight' ? env.innerHeight : pageBounds.below;
        const upperBound = pageBounds.above === 'screenheight' ? env.innerHeight : pageBounds.above;

        const closeCompanions = companionSets.map(companionSet => {
          return companionSet.find(otherSlot => {
            const otherBounds = otherSlot.getProperty('element').getBoundingClientRect();
            if (otherBounds.top - bounds.top > lowerBound) return false;
            if (bounds.top - otherBounds.bottom > upperBound) return false;
            return true;
          });
        });
        const allCompanionsValid = closeCompanions.every(
          (otherSlot): otherSlot is DataObject<Slot> => Boolean(otherSlot),
        );
        if (allCompanionsValid) {
          // Tandem Slots
          closeCompanions.forEach(companionSlot => {
            companionSlot.update({ masterID: slot.getProperty('id') });
          });
          const allSlots = [slot, ...closeCompanions];
          return allSlots.reduce((matches: null | Array<AdMatch>, tandemSlot) => {
            if (!matches) return matches; // If any failed, short-circuit: return null

            const adDefinition = findAdDefinition(tandemSlot, input);
            if (!adDefinition) return null;

            matches.push({
              slot: tandemSlot,
              adDefinition,
            });
            return matches;
          }, []);
        }
      }
    } else if (!slot.getProperty('master')) {
      // Normal slot
      const adDefinition = findAdDefinition(slot, input);
      if (adDefinition) {
        return [
          {
            slot,
            adDefinition,
          },
        ];
      }
    }
    return null;
  });
});

const createAds = fromPromise<
  Array<DataObject<Ad>>,
  Pick<SlotifyMachineContext, 'adMatches' | 'adCounter' | 'pageAdUnitPath' | 'adTypeCounters'>
>(async ({ input: { adMatches, adCounter, pageAdUnitPath, adTypeCounters } }) =>
  adMatches.map(({ slot, adDefinition }, index, list) => {
    const adID = `bordeaux-ad-${adCounter + index}`;
    if (slot) {
      slot.update({ adID });
    }

    let targeting: Targeting = {};
    if (slot) {
      targeting = {
        ...targeting,
        _slot: slot.getProperty('name'),
        _slot_type: slot.getProperty('genericName'),
      };
    }
    if (adDefinition.incremental) {
      targeting = {
        ...targeting,
        ...autoIncrementTargeting(adDefinition),
        adUnitName: [adDefinition.name],
      };
    }

    return createAd(adDefinition, {
      id: adID,
      targeting,
      ...(slot
        ? {
            slotID: slot.getProperty('id'),
          }
        : {}),
      ...(adDefinition.incremental
        ? {
            // ADP-10451: "limit the ad unit path on incremental ad units to a maximum of ten"
            adUnitPath: `${pageAdUnitPath}/${adDefinition.name}-${Math.min(10, list.slice(0, index).reduce((counter, item) => counter + (item.adDefinition.name === adDefinition.name ? 1 : 0), 0) + (adTypeCounters[adDefinition.name] || 0))}`,
          }
        : {
            adUnitPath: `${pageAdUnitPath}/${adDefinition.name}`,
          }),
    });
  }),
);

const createAdElements = (ad: DataObject<Ad>) => {
  switch (ad.getProperty('mode')) {
    case AdUnitMode.SLOTIFY:
      return createSlotifyMarkup(ad);
    case AdUnitMode.ANCHORED:
      return createAnchoredMarkup(ad);
    case AdUnitMode.OOP:
      return createOutOfPageMarkup(ad);
    case AdUnitMode.SKYSCRAPER:
      return createSkyscraperMarkup(ad);
    default:
      return null;
  }
};

const inlineLeftRightSlotsStore: Map<string, number> = new Map();

const computeAndStoreNbOccurencesOfCurrentSlot = (genericSlotName: string | undefined): number => {
  let nbSlotOccurences = 0;
  if (genericSlotName) {
    if (inlineLeftRightSlotsStore.get(genericSlotName) !== undefined) {
      nbSlotOccurences = (inlineLeftRightSlotsStore.get(genericSlotName) || 0) + 1;
    }
    inlineLeftRightSlotsStore.set(genericSlotName, nbSlotOccurences);
  }
  return nbSlotOccurences;
};

const insertAds = fromPromise<void, SlotifyMachineContext>(
  async ({ input: { newAds, slots } }): Promise<void> => {
    const env = getEnv();
    const results = await Promise.allSettled(
      newAds.map(async ad => {
        const mode = ad.getProperty('mode');
        const elements = createAdElements(ad);
        if (!elements) {
          throw new Error(
            'Ad cannot be inserted into the DOM because its elements could not be created',
          );
        }
        ad.update({ elements });
        switch (mode) {
          case AdUnitMode.OOP:
          case AdUnitMode.ANCHORED: {
            const container = elements?.outerContainer;
            if (!container) {
              throw new Error(
                'Ad cannot be inserted into the DOM because it has no outer container',
              );
            }
            await fastdom.mutate(() => {
              env.document.body.appendChild(container);
            });
            break;
          }
          case AdUnitMode.SKYSCRAPER: {
            const container = elements?.outerContainer;
            if (!container) {
              throw new Error(
                'Ad cannot be inserted into the DOM because it has no outer container',
              );
            }
            const env = getEnv();
            const main = env.document.getElementById('main');
            if (!main) {
              throw new Error('Error in skyscraper setup, #main element not found');
            }
            const { width: contentWidth } = await fastdom.measure(() =>
              main.getBoundingClientRect(),
            );

            const skyscraperContainer =
              querySelector<HTMLElement>('.skyscraper-container') ||
              env.document.createElement('div');

            skyscraperContainer.classList.add('skyscraper-container');
            const skyscraperContainerStyle = {
              width: `${contentWidth}px`,
              height: 0,
              margin: `auto`,
              position: 'sticky',
              top: 0,
              'pointer-events': 'none',
            };
            Object.assign(skyscraperContainer.style, skyscraperContainerStyle);

            await fastdom.mutate(() => {
              if (!main.parentNode) {
                throw new Error('Error in skyscraper setup, #main element has no parent');
              }
              skyscraperContainer.appendChild(container);
              main.parentNode.insertBefore(skyscraperContainer, main);
            });
            break;
          }
          case AdUnitMode.SLOTIFY: {
            const adElement = elements?.element;
            if (!adElement) {
              throw new Error('Ad cannot be inserted into the DOM because it has no element');
            }
            const slotID = ad.getProperty('slotID');
            const slot = slots.getValues().find(slot => slot.getProperty('id') === slotID);
            if (!(slotID && slot)) {
              throw new Error('Ad cannot be inserted into the DOM because it has no slot');
            }
            const slotElement = slot.getProperty('element');
            const slotLabel = slot.getProperty('label');
            const slotHasLabel = !(!slotLabel || isEmpty(slotLabel) || slotLabel.applyLabelToAds);
            const labelStyle = stringToStyle(slotLabel?.style || LABEL_STYLE);
            const slotStyles = getOnMountSlotStyles(
              slot,
              (slotHasLabel && labelStyle.height) || '0',
              computeAndStoreNbOccurencesOfCurrentSlot(slot.getProperty('genericName')),
            );

            const applyLabelToAds =
              slotLabel.applyLabelToAds && slotLabel.applyLabelToAds === 'true';
            if (applyLabelToAds) {
              ad.update({ label: slotLabel });
            }

            await fastdom.mutate(() => {
              applyStyles(slotElement, slotStyles);

              slotElement.classList.add('bordeaux-filled-slot');
              slotElement.ariaHidden = 'true';

              slotElement.appendChild(adElement);

              if (slotHasLabel) {
                const labelElement = createSlotLabel(slotID, slotLabel);
                if (slotLabel.position === SlotLabelPosition.ABOVE) {
                  slotElement.insertBefore(labelElement, slotElement.firstChild);
                }
                if (slotLabel.position === SlotLabelPosition.BELOW) {
                  slotElement.style.justifyContent = 'space-between';
                  slotElement.lastChild?.after(labelElement);
                }
              }
            });
            break;
          }
          default:
            throw new Error(`Ad insertion issue, ad has unrecognised mode: ${mode}`);
        }
      }),
    );
    const failed = results.filter(r => r.status === 'rejected');
    if (failed.length) {
      log.error('Ad elements could not be inserted', failed);
    }
  },
);

const slotifyMachine: SlotifyMachineDefinition = setup({
  types: {} as {
    context: SlotifyMachineContext;
    events: AnySlotifyEvent;
    input: BordeauxMachineContext;
  },
  actors: {
    staticSlotGenerator,
    dynamicSlotGenerator,
    affinityAdGenerator,
    slotWatcher,
    waitForSlotsReady,
    matchSlot,
    createAds,
    insertAds,
  },
  guards: {
    [SLOTIFY_GUARDS.ROADBLOCK_READY]: ({ context: { isRoadblock } }) => isRoadblock !== null,
    [SLOTIFY_GUARDS.STANDARD_ADS_ENABLED]: ({ context: { features } }) =>
      features[FEATURE.ADS_STANDARD],
    [SLOTIFY_GUARDS.INCREMENTAL_ADS_ENABLED]: ({ context: { features } }) =>
      features[FEATURE.ADS_INCREMENTAL],
    [SLOTIFY_GUARDS.SLOTS_TO_PROCESS]: ({ context: { slotStack } }) => slotStack.length !== 0,
    [SLOTIFY_GUARDS.SLOT_IS_NATIVE]: ({}, { slot }: { slot: DataObject<Slot> }) =>
      slot.getProperty('nativeContent'),
    [SLOTIFY_GUARDS.NATIVE_CONTENT_FILLED]: ({ context: { slots } }) =>
      slots
        .getValues()
        .every(slot => !slot.getProperty('nativeContent') || slot.getProperty('adID')),
    [SLOTIFY_GUARDS.ROADBLOCK_INCREMENTALS_FILLED]: ({
      context: {
        isRoadblock,
        roadblockIncrementalCaps,
        roadblockIncrementalCount,
        pageParameters: { device },
        config: {
          placement: {
            settings: { roadblock },
          },
        },
      },
    }) => {
      // Makes sure we never exceed the roadblock incremental cap

      if (!isRoadblock) return false;

      const incrementalCap = roadblockIncrementalCaps?.[device] ?? roadblock?.incrementalCap;

      if (!incrementalCap) {
        return true;
      }

      if (roadblockIncrementalCount >= incrementalCap) {
        return true;
      }

      return false;
    },
    [SLOTIFY_GUARDS.INCREMENTALS_STARTED]: ({ context: { incrementalsStarted } }) =>
      incrementalsStarted,
  },
  actions: {
    [SLOTIFY_ACTIONS.REPORT_AD_AFFINITY_FAILED]: (
      {
        context: {
          config: {
            placement: {
              slots: { static: slotDefinitions },
            },
          },
        },
      },
      { adDefinition, reason }: { adDefinition: AdDefinition; reason: AD_AFFINITY_FAILED_REASONS },
    ) => {
      switch (reason) {
        case AD_AFFINITY_FAILED_REASONS.ABSENT:
          log.error(
            `Slotify standard handling error - Affinity undefined for ${adDefinition.name}`,
          );
          break;
        case AD_AFFINITY_FAILED_REASONS.NO_SLOT: {
          const slotDefinition = slotDefinitions.find(
            searchSlot => searchSlot.name === adDefinition.affinitySlotID,
          );
          if (slotDefinition) {
            if (!slotDefinition.ignoreErrors) {
              log.error(
                `The ad unit '${adDefinition.name}' has a slot affinity '${adDefinition.affinitySlotID}' but the slot could not be created.`,
              );
            }
          } else {
            log.error(
              `The ad unit '${adDefinition.name}' has a slot affinity '${adDefinition.affinitySlotID}' but no matching slot was configured.`,
            );
          }
          break;
        }
        case AD_AFFINITY_FAILED_REASONS.SLOT_FILLED:
          log.error(
            `Slotify standard handling error - Slot already filled for ${adDefinition.name}`,
          );
          break;
        default:
          log.error(
            `The ad unit '${adDefinition.name}' has a slot affinity '${adDefinition.affinitySlotID}' but something went wrong.`,
          );
      }
    },
    [SLOTIFY_ACTIONS.RAISE_ENABLE_INCREMENTAL_ADS]: raise({
      type: SLOTIFY_EVENTS.INCREMENTAL_ADS_ENABLED,
    }),
    [SLOTIFY_ACTIONS.RAISE_ROADBLOCK_READY]: raise({ type: SLOTIFY_EVENTS.ROADBLOCK_READY }),

    [SLOTIFY_ACTIONS.RAISE_ENABLE_STANDARD_ADS]: raise({ type: STANDARD_ADS_EVENTS_IN.ENABLED }),
    [SLOTIFY_ACTIONS.ENABLE_INCREMENTAL_ADS]: assign({
      features: ({ context: { features } }) => ({
        ...features,
        [FEATURE.ADS_INCREMENTAL]: true,
      }),
    }),
    [SLOTIFY_ACTIONS.ENABLE_STANDARD_ADS]: assign({
      features: ({ context: { features } }) => ({
        ...features,
        [FEATURE.ADS_STANDARD]: true,
      }),
    }),
    [SLOTIFY_ACTIONS.CHECK_ROADBLOCK_STATUS]: sendParent({
      type: BDX_EVENTS.CHECK_ROADBLOCK_STATUS,
    }),
    [SLOTIFY_ACTIONS.UPDATE_ROADBLOCK_STATUS]: assign({
      isRoadblock: (_, newStatus: boolean) => newStatus,
    }),

    [SLOTIFY_ACTIONS.POSITION_SLOT_ELEMENT]: (_, slot: DataObject<Slot>) => {
      slot.update({
        readyPromise: fastdom.mutate(() => {
          positionElement(
            slot.getProperty('element'),
            slot.getProperty('position'),
            slot.getProperty('hookElement'),
          );
        }),
      });
    },
    [SLOTIFY_ACTIONS.ADD_SLOT]: assign({
      slots: ({ context: { slots } }, slot: DataObject<Slot>) => {
        slots.push(slot);
        return slots;
      },
    }),
    [SLOTIFY_ACTIONS.CREATE_ADDITIONAL_SLOT_WATCHERS]: (_, slot: DataObject<Slot>) => {
      addAdditionalWatchers(slot);
    },
    [SLOTIFY_ACTIONS.CREATE_SLOT_WATCHER]: assign({
      slotWatchers: ({ context, spawn }, slot: DataObject<Slot>) => [
        ...context.slotWatchers,
        spawn('slotWatcher', {
          id: `slotWatcher-${slot.getProperty('id')}`,
          input: {
            slot,
            activationDistance: context.activationDistance,
          },
        }),
      ],
    }),
    [SLOTIFY_ACTIONS.REPORT_SLOT_HOOK_FAILED]: (_, slotDefinition: SlotDefinition) => {
      if (slotDefinition.ignoreErrors) return;
      if (slotDefinition.multiple) {
        log.error(
          `Static slot ${slotDefinition.name} could not find any elements with hook '${slotDefinition.hook}'.`,
        );
      } else {
        log.error(
          `Static slot ${slotDefinition.name} could not find an element with hook '${slotDefinition.hook}'.`,
        );
      }
    },
    [SLOTIFY_ACTIONS.CREATE_DYNAMIC_SLOT_GENERATOR]: assign({
      slotGenerator: ({ spawn, context }) =>
        spawn('dynamicSlotGenerator', {
          id: 'dynamicSlotMachine',
          input: {
            generatedSlotDefinitions: context.config.placement.slots.generated.map(
              replaceConstantsInSlot(context.pageStyleConstants),
            ),
            dynamicSlotDefinitions: context.config.placement.slots.dynamic.map(
              replaceConstantsInSlot(context.pageStyleConstants),
            ),
            automaticDynamic: context.automaticDynamic,
          },
        }),
    }),
    [SLOTIFY_ACTIONS.WATCH_EXCLUSION_ZONES]: watchExclusionZones,

    [SLOTIFY_ACTIONS.INCREMENT_AD_TYPE_COUNTERS]: assign({
      adTypeCounters: ({ context: { adTypeCounters } }, newAds: Array<DataObject<Ad>>) =>
        newAds.reduce(
          (adTypeCounters, ad) => ({
            ...adTypeCounters,
            [ad.getProperty('name')]: 1 + (adTypeCounters[ad.getProperty('name')] || 0),
          }),
          adTypeCounters,
        ),
    }),
    [SLOTIFY_ACTIONS.INCREMENT_AD_COUNTER]: assign({
      adCounter: ({ context: { adCounter } }, newAds: Array<DataObject<Ad>>) =>
        adCounter + newAds.length,
    }),
    [SLOTIFY_ACTIONS.INCREMENT_ROADBLOCK_INCREMENTAL_COUNTER]: assign({
      roadblockIncrementalCount: (
        { context: { isRoadblock, roadblockIncrementalCount } },
        newAds: Array<DataObject<Ad>>,
      ) =>
        roadblockIncrementalCount +
        (isRoadblock ? newAds.filter(ad => !(ad && ad.getProperty('nativeContent'))).length : 0),
    }),
    [SLOTIFY_ACTIONS.ADD_SLOT_TO_STACK]: assign({
      slotStack: ({ context: { slotStack } }, { slot }: { slot: DataObject<Slot> }) => [
        ...slotStack,
        slot,
      ],
    }),
    [SLOTIFY_ACTIONS.REORDER_ADS]: assign({ newAds: reorderAds }),
  },
}).createMachine({
  id: 'slotify',
  context: ({ input }) => ({
    ...input,
    incrementalsStarted: false,
    roadblockIncrementalCount: 0,
    batchCounter: 0,
    adCounter: 0,
    adTypeCounters: {},
    adMatches: [],
    newAds: [],

    slotStack: [],
    slotWatchers: [],
    slotGenerator: {} as AnyActorRef,
  }),
  initial: SLOTIFY_STATES.CREATING_STATIC_SLOTS,
  states: {
    [SLOTIFY_STATES.CREATING_STATIC_SLOTS]: {
      invoke: {
        src: 'staticSlotGenerator',
        input: ({ context }) => ({
          slotDefinitions: context.config.placement.slots.static.map(
            replaceConstantsInSlot(context.pageStyleConstants),
          ),
        }),
      },
      on: {
        [SLOTIFY_EVENTS.STATIC_SLOTS_DONE]: {
          target: SLOTIFY_STATES.WAIT_FOR_STATIC_SLOTS_READY,
        },
      },
    },
    [SLOTIFY_STATES.WAIT_FOR_STATIC_SLOTS_READY]: {
      invoke: {
        src: 'waitForSlotsReady',
        input: ({ context }) => ({ slots: context.slots.getValues() }),
        onDone: {
          target: SLOTIFY_STATES.WAIT_FOR_STANDARD_ADS_ENABLED,
        },
      },
    },
    [SLOTIFY_STATES.WAIT_FOR_STANDARD_ADS_ENABLED]: {
      entry: enqueueActions(({ enqueue, check }) => {
        if (check(SLOTIFY_GUARDS.STANDARD_ADS_ENABLED)) {
          enqueue(SLOTIFY_ACTIONS.RAISE_ENABLE_STANDARD_ADS);
        }
      }),
      on: {
        [STANDARD_ADS_EVENTS_IN.ENABLED]: {
          target: SLOTIFY_STATES.YIELDING_STATIC_AFFINITY_ADS,
        },
      },
    },
    [SLOTIFY_STATES.YIELDING_STATIC_AFFINITY_ADS]: {
      invoke: {
        src: 'affinityAdGenerator',
        input: ({ context }) => ({
          adDefinitions: context.adUnits.standard,
          slots: context.slots.getValues(),
        }),
      },
      on: {
        [SLOTIFY_EVENTS.ADS_MATCH]: {
          actions: [
            assign({
              adMatches: ({ event: { data } }) =>
                data.map(({ adDefinition, slot }) => ({
                  slot,
                  adDefinition,
                })),
            }),
          ],
          target: SLOTIFY_STATES.CREATE_ADS,
        },
      },
    },
    [SLOTIFY_STATES.WAIT_FOR_ROADBLOCK_READY]: {
      entry: enqueueActions(({ enqueue, check }) => {
        if (check(SLOTIFY_GUARDS.ROADBLOCK_READY)) {
          enqueue(SLOTIFY_ACTIONS.RAISE_ROADBLOCK_READY);
        } else {
          enqueue(SLOTIFY_ACTIONS.CHECK_ROADBLOCK_STATUS);
        }
      }),
      on: {
        [INCREMENTAL_ADS_EVENTS_IN.ROADBLOCK_STATUS]: {
          actions: [
            { type: SLOTIFY_ACTIONS.UPDATE_ROADBLOCK_STATUS, params: ({ event }) => event.data },
            enqueueActions(({ enqueue, check }) => {
              if (check(SLOTIFY_GUARDS.ROADBLOCK_READY)) {
                enqueue(SLOTIFY_ACTIONS.RAISE_ROADBLOCK_READY);
              } else {
                enqueue(SLOTIFY_ACTIONS.CHECK_ROADBLOCK_STATUS);
              }
            }),
          ],
        },
        [SLOTIFY_EVENTS.ROADBLOCK_READY]: [
          {
            // ADP-12918 anchored ads should be avoided if roadblock is active
            guard: ({
              context: {
                isRoadblock,
                adUnits: { standard: adDefinitions },
              },
            }) =>
              !isRoadblock &&
              adDefinitions.some(
                adDefinition =>
                  adDefinition.mode === AdUnitMode.ANCHORED && !adDefinition.inRoadblock,
              ),
            actions: [
              assign({
                adMatches: ({
                  context: {
                    adUnits: { standard: adDefinitions },
                  },
                }) =>
                  adDefinitions
                    .filter(
                      adDefinition =>
                        adDefinition.mode === AdUnitMode.ANCHORED && !adDefinition.inRoadblock,
                    )
                    .map(adDefinition => ({
                      adDefinition,
                    })),
              }),
            ],
            target: SLOTIFY_STATES.CREATE_ADS,
          },
          {
            target: SLOTIFY_STATES.WAIT_FOR_INCREMENTAL_ADS_ENABLED,
          },
        ],
      },
    },
    [SLOTIFY_STATES.WAIT_FOR_INCREMENTAL_ADS_ENABLED]: {
      entry: enqueueActions(({ enqueue, check }) => {
        if (check(SLOTIFY_GUARDS.INCREMENTAL_ADS_ENABLED)) {
          enqueue(SLOTIFY_ACTIONS.RAISE_ENABLE_INCREMENTAL_ADS);
        }
      }),
      on: {
        [INCREMENTAL_ADS_EVENTS_IN.ENABLED]: {
          actions: [
            SLOTIFY_ACTIONS.ENABLE_INCREMENTAL_ADS,
            enqueueActions(({ enqueue, check }) => {
              if (check(SLOTIFY_GUARDS.INCREMENTAL_ADS_ENABLED)) {
                enqueue(SLOTIFY_ACTIONS.RAISE_ENABLE_INCREMENTAL_ADS);
              }
            }),
          ],
        },
        [SLOTIFY_EVENTS.INCREMENTAL_ADS_ENABLED]: {
          target: SLOTIFY_STATES.WATCHING_INCREMENTAL_SLOTS,
        },
      },
    },
    [SLOTIFY_STATES.WATCHING_INCREMENTAL_SLOTS]: {
      entry: [
        assign({ incrementalsStarted: true }),
        SLOTIFY_ACTIONS.CREATE_DYNAMIC_SLOT_GENERATOR,
        SLOTIFY_ACTIONS.WATCH_EXCLUSION_ZONES,
      ],
      always: {
        target: SLOTIFY_STATES.WAITING_FOR_SLOTS,
      },
    },
    [SLOTIFY_STATES.WAITING_FOR_SLOTS]: {
      always: [
        {
          // Do nothing, keep waiting
          guard: not(SLOTIFY_GUARDS.SLOTS_TO_PROCESS),
        },
        {
          // ADP-13241 Always process native slots
          guard: {
            type: SLOTIFY_GUARDS.SLOT_IS_NATIVE,
            params: ({ context: { slotStack } }) => ({ slot: slotStack[0] }),
          },
          target: SLOTIFY_STATES.PROCESSING_SLOT_STACK,
        },
        {
          // ADP-13056 Incrementals should continue when roadblocked to allow a limited number to be generated
          guard: not(SLOTIFY_GUARDS.ROADBLOCK_INCREMENTALS_FILLED),
          target: SLOTIFY_STATES.PROCESSING_SLOT_STACK,
        },
        {
          // ADP-13241 Only stop after all incrementals and native content are filled
          guard: SLOTIFY_GUARDS.NATIVE_CONTENT_FILLED,
          target: SLOTIFY_STATES.DONE,
        },
      ],
    },
    [SLOTIFY_STATES.PROCESSING_SLOT_STACK]: {
      invoke: {
        src: 'matchSlot',
        input: ({
          context: {
            slotStack,
            slots,
            overrideCompanionBounds,
            config,
            isRoadblock,
            avoidanceDistance,
            roadblockIncrementals,
            adUnits,
            ads,
          },
        }) => ({
          slots,
          overrideCompanionBounds,
          config,
          isRoadblock,
          avoidanceDistance,
          roadblockIncrementals,
          adUnits,
          ads,
          slot: slotStack[0],
        }),
        onDone: [
          {
            guard: ({ event: { output } }) => output !== null,
            actions: [
              assign({
                slotStack: ({ context: { slotStack } }) => slotStack.slice(1),
              }),
              assign({
                adMatches: ({ event: { output } }) => output!,
              }),
            ],
            target: SLOTIFY_STATES.CREATE_ADS,
          },
          {
            actions: [
              assign({
                slotStack: ({ context: { slotStack } }) => slotStack.slice(1),
              }),
            ],
            target: SLOTIFY_STATES.WAITING_FOR_SLOTS,
          },
        ],
      },
    },
    [SLOTIFY_STATES.CREATE_ADS]: {
      invoke: {
        src: 'createAds',
        input: ({ context: { adMatches, adCounter, pageAdUnitPath, adTypeCounters } }) => ({
          adMatches,
          adCounter,
          pageAdUnitPath,
          adTypeCounters,
        }),
        onDone: {
          actions: [
            {
              type: SLOTIFY_ACTIONS.INCREMENT_AD_COUNTER,
              params: ({ event: { output } }) => output,
            },
            {
              type: SLOTIFY_ACTIONS.INCREMENT_ROADBLOCK_INCREMENTAL_COUNTER,
              params: ({ event: { output } }) => output,
            },
            {
              type: SLOTIFY_ACTIONS.INCREMENT_AD_TYPE_COUNTERS,
              params: ({ event: { output } }) => output,
            },
            assign({
              newAds: ({ event: { output } }) => output,
            }),
          ],
          target: SLOTIFY_STATES.INSERT_ADS,
        },
      },
    },
    [SLOTIFY_STATES.INSERT_ADS]: {
      entry: [
        ({ context: { ads, newAds } }) => {
          newAds.forEach(ad => {
            ads.push(ad);
          });
        },
      ],
      invoke: {
        src: 'insertAds',
        input: ({ context }) => context,
        onDone: { target: SLOTIFY_STATES.REQUEST_ADS },
      },
    },
    [SLOTIFY_STATES.REQUEST_ADS]: {
      entry: [
        SLOTIFY_ACTIONS.REORDER_ADS,
        ({ context: { batchCounter } }) => {
          if (batchCounter !== 0) return;
          adsLoaded();
        },
        sendParent(({ context: { newAds } }) => ({
          type: BDX_EVENTS.REQUEST_AUCTION,
          data: { ads: newAds },
        })),
        assign({
          adMatches: [],
          newAds: [],
          batchCounter: ({ context: { batchCounter } }) => batchCounter + 1,
        }),
      ],
      always: [
        {
          guard: not(SLOTIFY_GUARDS.ROADBLOCK_READY),
          target: SLOTIFY_STATES.WAIT_FOR_ROADBLOCK_READY,
        },
        {
          guard: not(SLOTIFY_GUARDS.INCREMENTAL_ADS_ENABLED),
          target: SLOTIFY_STATES.WAIT_FOR_INCREMENTAL_ADS_ENABLED,
        },
        {
          guard: not(SLOTIFY_GUARDS.INCREMENTALS_STARTED),
          target: SLOTIFY_STATES.WATCHING_INCREMENTAL_SLOTS,
        },
        {
          target: SLOTIFY_STATES.WAITING_FOR_SLOTS,
        },
      ],
    },
    [SLOTIFY_STATES.DONE]: {},
  },
  on: {
    [SLOTIFY_EVENTS.SLOT_HOOK_FAILED]: {
      actions: { type: SLOTIFY_ACTIONS.REPORT_SLOT_HOOK_FAILED, params: ({ event }) => event.data },
    },
    [SLOTIFY_EVENTS.SLOT_CREATED]: {
      actions: [
        { type: SLOTIFY_ACTIONS.ADD_SLOT, params: ({ event }) => event.data },
        { type: SLOTIFY_ACTIONS.POSITION_SLOT_ELEMENT, params: ({ event }) => event.data },
        {
          type: SLOTIFY_ACTIONS.CREATE_ADDITIONAL_SLOT_WATCHERS,
          params: ({ event }) => event.data,
        },
        { type: SLOTIFY_ACTIONS.CREATE_SLOT_WATCHER, params: ({ event }) => event.data },
      ],
    },
    [STANDARD_ADS_EVENTS_IN.ENABLED]: {
      actions: SLOTIFY_ACTIONS.ENABLE_STANDARD_ADS,
    },
    [INCREMENTAL_ADS_EVENTS_IN.ENABLED]: {
      actions: SLOTIFY_ACTIONS.ENABLE_INCREMENTAL_ADS,
    },
    [SLOTIFY_EVENTS.AD_AFFINITY_FAILED]: {
      actions: {
        type: SLOTIFY_ACTIONS.REPORT_AD_AFFINITY_FAILED,
        params: ({ event }) => event.data,
      },
    },
    [SLOTIFY_EVENTS.SLOT_IN_VIEW]: [
      {
        guard: not(stateIn(SLOTIFY_STATES.WAITING_FOR_SLOTS)),
        actions: {
          type: SLOTIFY_ACTIONS.ADD_SLOT_TO_STACK,
          params: ({ event: { data: slot } }) => ({ slot }),
        },
      },
      {
        guard: {
          type: SLOTIFY_GUARDS.SLOT_IS_NATIVE,
          params: ({ event: { data: slot } }) => ({ slot }),
        },
        actions: {
          type: SLOTIFY_ACTIONS.ADD_SLOT_TO_STACK,
          params: ({ event: { data: slot } }) => ({ slot }),
        },
        target: `.${SLOTIFY_STATES.PROCESSING_SLOT_STACK}`,
      },
      {
        guard: not(SLOTIFY_GUARDS.ROADBLOCK_INCREMENTALS_FILLED),
        actions: {
          type: SLOTIFY_ACTIONS.ADD_SLOT_TO_STACK,
          params: ({ event: { data: slot } }) => ({ slot }),
        },
        target: `.${SLOTIFY_STATES.PROCESSING_SLOT_STACK}`,
      },
    ],
    [SLOTIFY_EVENTS.FIND_NEW_DYNAMIC_SLOTS]: {
      actions: sendTo('dynamicSlotMachine', {
        type: 'refresh',
      }),
    },
  },
});

export default slotifyMachine;

const findAdDefinition = (
  slot: DataObject<Slot>,
  context: Pick<
    SlotifyMachineContext,
    'isRoadblock' | 'avoidanceDistance' | 'roadblockIncrementals' | 'adUnits' | 'slots' | 'ads'
  >,
): null | AdDefinition => {
  const {
    isRoadblock,
    avoidanceDistance,
    roadblockIncrementals,
    adUnits: { incremental: adDefinitions },
    slots,
    ads,
  } = context;
  const selectedAdDefinitions =
    isRoadblock && !slot.getProperty('nativeContent') ? roadblockIncrementals : adDefinitions;

  if (selectedAdDefinitions.length === 0) {
    return null;
  }

  if (slot.getProperty('adID') !== undefined) {
    return null;
  }
  if (!passesAdditionalAvoidance(avoidanceDistance, slot)) {
    return null;
  }
  if (!slot.getProperty('ignoreExclusion') && overlapsExclusionZones(slot)) {
    return null;
  }
  return selectedAdDefinitions.reduce(
    findBestAdForSlot(
      {
        slots: slots.getValues(),
        ads: ads.getValues(),
        avoidanceDistance,
      },
      slot,
    ),
    null,
  );
};
