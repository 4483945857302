import { log } from '@repo/utils';
import {
  EnableFeatureApiEvent,
  SetLoadGptExternallyApiEvent,
  SetAuctionTimeoutsApiEvent,
  SetAutomaticDynamicApiEvent,
  SetPageCategoryApiEvent,
  SetPageTemplateApiEvent,
  SetRefreshTimeApiEvent,
  SetRoadblockIncrementalCapsApiEvent,
  SetRoadblockIncrementalChooserApiEvent,
  SetActivationDistanceApiEvent,
  SetThirdPartyApiConfigApiEvent,
  SetDeviceAvoidanceDistanceApiEvent,
  SetPageTargetingApiEvent,
  SetExperimentIdApiEvent,
  SetAdToolVersionApiEvent,
  SetPrebidAnalyticsEnabledApiEvent,
  DisableFeatureApiEvent,
  GptLoadedExternallyApiEvent,
  SetCompanionBoundsApiEvent,
  SetFallbackResponsesApiEvent,
  AddUnrefreshableNamesApiEvent,
  FEATURE,
  ActionArgs,
  BordeauxMachineContext,
  API_EVENTS_OUT,
  SLOTIFY_EVENTS,
  INCREMENTAL_ADS_EVENTS_IN,
  STANDARD_ADS_EVENTS_IN,
  REFRESH_EVENTS,
  SetRefreshPausedApiEvent,
  SetRefreshPausedEvent as SetExternalRefreshPausedEvent,
} from '@repo/shared-types';
import { ACTIONS_RECORD } from './report';
import assign from './proxy/assign';
import enqueueActions from './proxy/enqueueActions';
import GuardArgs from './proxy/guard-args.types';
import sendTo from './proxy/send-to';

export default {
  [API_EVENTS_OUT.SET_ADTOOL_VERSION]: {
    actions: assign<SetAdToolVersionApiEvent>({
      adToolVersion: ({ event }) => event.data,
    }),
  },
  [API_EVENTS_OUT.SET_LOAD_GPT_EXTERNALLY]: {
    actions: assign<SetLoadGptExternallyApiEvent>({
      loadGptExternally: ({ event }) => event.data,
    }),
  },
  [API_EVENTS_OUT.GPT_LOADED_EXTERNALLY]: {
    actions: ({ context, event }: ActionArgs<GptLoadedExternallyApiEvent>): void => {
      context.loadGptExternallyPromise.resolve(event.data);
    },
  },
  [API_EVENTS_OUT.SET_PREBID_ANALYTICS_ENABLED]: {
    actions: assign<SetPrebidAnalyticsEnabledApiEvent>({
      prebidAnalyticsEnabled: ({ event }) => event.data,
    }),
  },
  [API_EVENTS_OUT.SET_AUCTION_TIMEOUTS]: {
    actions: assign<SetAuctionTimeoutsApiEvent>({
      auctionTimeouts: ({ event }) => event.data,
    }),
  },
  [API_EVENTS_OUT.SET_AUTOMATIC_DYNAMIC]: {
    actions: assign<SetAutomaticDynamicApiEvent>({
      automaticDynamic: ({ event }) => event.data,
    }),
  },
  [API_EVENTS_OUT.SET_COMPANION_BOUNDS]: {
    actions: assign<SetCompanionBoundsApiEvent>({
      overrideCompanionBounds: ({ event }) => event.data,
    }),
  },
  [API_EVENTS_OUT.SET_EXPERIMENT_ID]: {
    actions: enqueueActions<SetExperimentIdApiEvent>(({ check, enqueue }) => {
      if (
        check(
          ({ context }: GuardArgs<SetExperimentIdApiEvent>): boolean =>
            context.experimentId.length === 0,
        )
      ) {
        enqueue(
          assign<SetExperimentIdApiEvent>({
            experimentId: ({ event }) => event.data,
          }),
        );
        enqueue(
          assign<SetExperimentIdApiEvent>({
            timing: ({ context }) => ({
              ...context.timing,
              enabled: true,
            }),
          }),
        );
        enqueue(ACTIONS_RECORD.EXPERIMENT_ID);
      } else {
        enqueue(({ context, event }: ActionArgs<SetExperimentIdApiEvent>): void => {
          log.warn(
            `Bordeaux experiment id has already been set to ${context.experimentId}, not setting ${event.data}`,
          );
        });
      }
    }),
  },
  [API_EVENTS_OUT.SET_PAGE_CATEGORY]: {
    actions: assign<SetPageCategoryApiEvent>({
      pageCategory: ({ event }) => event.data,
    }),
  },
  [API_EVENTS_OUT.SET_PAGE_TEMPLATE]: {
    actions: assign<SetPageTemplateApiEvent>({
      pageTemplate: ({ event }) => event.data,
    }),
  },
  [API_EVENTS_OUT.SET_REFRESH_TIME]: {
    actions: assign<SetRefreshTimeApiEvent>({
      refreshTime: ({ event }) => event.data,
    }),
  },
  [API_EVENTS_OUT.SET_ROADBLOCK_INCREMENTAL_CAPS]: {
    actions: assign<SetRoadblockIncrementalCapsApiEvent>({
      roadblockIncrementalCaps: ({ event }) => event.data,
    }),
  },
  [API_EVENTS_OUT.SET_ROADBLOCK_INCREMENTAL_CHOOSER]: {
    actions: assign<SetRoadblockIncrementalChooserApiEvent>({
      roadblockIncrementalChooser: ({ event }) => event.data,
    }),
  },
  [API_EVENTS_OUT.SET_ACTIVATION_DISTANCE]: {
    actions: assign<SetActivationDistanceApiEvent>({
      activationDistance: ({ event }) => event.data,
    }),
  },
  [API_EVENTS_OUT.SET_AVOIDANCE_DISTANCE]: {
    actions: assign<SetDeviceAvoidanceDistanceApiEvent>({
      deviceAvoidanceDistance: ({ event }) => event.data,
    }),
  },
  [API_EVENTS_OUT.SET_THIRD_PARTY_API_CONFIG]: {
    actions: assign<SetThirdPartyApiConfigApiEvent>({
      thirdPartyApiConfigOverrides: ({ event }) => event.data,
    }),
  },
  [API_EVENTS_OUT.SET_PAGE_TARGETING]: {
    actions: assign<SetPageTargetingApiEvent>({
      pageTargeting: ({ context, event }) => ({
        ...context.pageTargeting,
        ...event.data,
      }),
    }),
  },
  [API_EVENTS_OUT.ENABLE_FEATURE]: {
    actions: enqueueActions<EnableFeatureApiEvent>(({ check, enqueue }) => {
      if (
        check(
          ({ context, event }: GuardArgs<EnableFeatureApiEvent>): boolean =>
            !context.features[event.data],
        )
      ) {
        enqueue(
          assign<EnableFeatureApiEvent>({
            features: ({ context, event }) => ({
              ...context.features,
              [event.data]: true,
            }),
          }),
        );
        if (
          check(
            ({ event }: GuardArgs<EnableFeatureApiEvent>): boolean =>
              event.data === FEATURE.ADS_REFRESH,
          )
        ) {
          enqueue(
            sendTo<BordeauxMachineContext['automaticRefreshMachine'], EnableFeatureApiEvent>(
              ({ context }) => context.automaticRefreshMachine,
              {
                type: REFRESH_EVENTS.SET_FEATURE_ENABLED,
                data: true,
              },
            ),
          );
        } else if (
          check(
            ({ event }: GuardArgs<EnableFeatureApiEvent>): boolean =>
              event.data === FEATURE.ADS_STANDARD,
          )
        ) {
          enqueue(
            sendTo('slotify', {
              type: STANDARD_ADS_EVENTS_IN.ENABLED,
            }),
          );
        } else if (
          check(
            ({ event }: GuardArgs<EnableFeatureApiEvent>): boolean =>
              event.data === FEATURE.ADS_INCREMENTAL,
          )
        ) {
          enqueue(
            sendTo('slotify', {
              type: INCREMENTAL_ADS_EVENTS_IN.ENABLED,
            }),
          );
        }
      }
    }),
  },
  [API_EVENTS_OUT.DISABLE_FEATURE]: {
    actions: enqueueActions<DisableFeatureApiEvent>(({ check, enqueue }) => {
      if (
        check(
          ({ context }: GuardArgs<DisableFeatureApiEvent>): boolean => !context.featuresInitialised,
        )
      ) {
        enqueue(
          assign<DisableFeatureApiEvent>({
            features: ({ context, event }) => ({
              ...context.features,
              [event.data]: false,
            }),
          }),
        );
        if (
          check(
            ({ event }: GuardArgs<DisableFeatureApiEvent>): boolean =>
              event.data === FEATURE.ADS_REFRESH,
          )
        ) {
          enqueue(
            sendTo<BordeauxMachineContext['automaticRefreshMachine'], DisableFeatureApiEvent>(
              ({ context }) => context.automaticRefreshMachine,
              {
                type: REFRESH_EVENTS.SET_FEATURE_ENABLED,
                data: false,
              },
            ),
          );
        }
      } else {
        enqueue(({ event }: ActionArgs<DisableFeatureApiEvent>): void => {
          log.warn(`Cannot disable the ${event.data} feature after Bordeaux has initialised.`);
        });
      }
    }),
  },
  [API_EVENTS_OUT.SET_REFRESH_PAUSED]: {
    actions: sendTo<BordeauxMachineContext['automaticRefreshMachine'], SetRefreshPausedApiEvent>(
      ({ context }) => context.automaticRefreshMachine,
      ({ event }): SetExternalRefreshPausedEvent => ({
        type: REFRESH_EVENTS.SET_REFRESH_PAUSED,
        data: event.data,
      }),
    ),
  },
  [API_EVENTS_OUT.SET_FALLBACK_RESPONSES]: {
    actions: assign<SetFallbackResponsesApiEvent>({
      fallbackResponses: ({ context, event }) => ({
        ...context.fallbackResponses,
        ...event.data,
      }),
    }),
  },
  [API_EVENTS_OUT.REQUEST_HANDLE_DYNAMIC_SLOTS]: {
    actions: sendTo('slotify', {
      type: SLOTIFY_EVENTS.FIND_NEW_DYNAMIC_SLOTS,
    }),
  },
  [API_EVENTS_OUT.ADD_UNREFRESHABLE_NAMES]: {
    actions: assign<AddUnrefreshableNamesApiEvent>({
      unrefreshableNames: ({ context, event }) => [...context.unrefreshableNames, ...event.data],
    }),
  },
};
