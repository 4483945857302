import { Ad, DataObject, BordeauxMachineContext } from '@repo/shared-types';
import { adCanRefresh, tandemAdsPresent } from '../logic';

export const getAdsToRefresh = (context: BordeauxMachineContext): Array<DataObject<Ad>> => {
  const ads = context.ads.getValues();

  if (!context.config.placement) {
    return [];
  }

  const refreshTime =
    context.refreshTime ||
    context.config.placement.settings.refreshTime ||
    Number.POSITIVE_INFINITY;

  const adsCanRefresh = ads
    .filter(adCanRefresh(context))
    .filter(ad => (ad.getProperty('viewedTime') || 0) >= refreshTime);

  const adsToRefresh = tandemAdsPresent(adsCanRefresh, context.slots.getValues());

  return adsToRefresh;
};
