import {
  DeviceOptions,
  DataObject,
  ThirdParty,
  BordeauxMachineContext,
  Ad,
  EVENTS,
} from '@repo/shared-types';
import adServerProcess from './ad-server';
import amazonProcess from './amazon';
import iasProcess from './ias';
import preBidProcess from './prebid';
import { AnyEventObject, fromCallback } from 'xstate';
import getTargeting from 'ad-framework/targeting';
import { log, replaceAmpersand } from '@repo/utils';
import { AuctionProcessedEvent } from '@repo/shared-types/src/types/state/events.types';

const getPreProcessTimeout = (context: BordeauxMachineContext, auctionId: number): number => {
  const defaultTimeout = 3000;
  const timeouts = {
    [DeviceOptions.DESKTOP]: 3000,
    [DeviceOptions.TABLET]: 3000,
    [DeviceOptions.MOBILE]: 3000,
  };
  const { device } = context.pageParameters;
  const overrideTimeouts = context.auctionTimeouts;

  if (auctionId > 1 && overrideTimeouts && overrideTimeouts[device]) {
    return overrideTimeouts[device];
  }

  if (timeouts[device]) {
    return timeouts[device];
  }

  return defaultTimeout;
};

export const adPreProcessor = fromCallback<
  AnyEventObject,
  {
    context: BordeauxMachineContext;
    auctionId: number;
    ads: Array<DataObject<Ad>>;
  },
  AnyEventObject
>(({ input: { context, ads, auctionId }, sendBack }) => {
  const pageTargeting = replaceAmpersand(getTargeting(context));

  const apiSetupResults = context.thirdPartyResults;
  const PRE_PROCESS_TIMEOUT = getPreProcessTimeout(context, auctionId);

  const processors: Array<Promise<string>> = [];
  if (
    apiSetupResults[ThirdParty.PREBID]?.config.enabled &&
    apiSetupResults[ThirdParty.PREBID]?.success
  ) {
    const { config } = apiSetupResults[ThirdParty.PREBID];
    processors.push(
      preBidProcess(
        sendBack,
        context.config.bidFloors.floorPrices,
        context.config.bidFloors.useForPrebid,
        config.banner,
        ads,
        PRE_PROCESS_TIMEOUT,
        auctionId,
        pageTargeting,
      ),
    );
  }
  if (
    apiSetupResults[ThirdParty.AD_SERVER].config.enabled &&
    apiSetupResults[ThirdParty.AD_SERVER].success
  ) {
    processors.push(
      adServerProcess(
        sendBack,
        context,
        apiSetupResults[ThirdParty.AD_SERVER].config.ab,
        ads,
        PRE_PROCESS_TIMEOUT,
        auctionId,
      ),
    );
  }
  if (
    apiSetupResults[ThirdParty.AMAZON]?.config.enabled &&
    apiSetupResults[ThirdParty.AMAZON].success
  ) {
    processors.push(amazonProcess(sendBack, ads, PRE_PROCESS_TIMEOUT, auctionId));
  }
  if (apiSetupResults[ThirdParty.IAS]?.config.enabled && apiSetupResults[ThirdParty.IAS]?.success) {
    processors.push(iasProcess(sendBack, ads, PRE_PROCESS_TIMEOUT, auctionId));
  }

  Promise.all(processors)
    .then(() => {
      sendBack({
        type: EVENTS.AUCTION_PROCESSED,
        data: {
          auctionId,
        },
      } as AuctionProcessedEvent);
    })
    .catch(error => {
      log.error('GAM API fetch error: ', error);
    });
});
