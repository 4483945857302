import { setup } from 'xstate';

import {
  cookies,
  getGDPRConsentMachine,
  getGPPConsent,
  getRefreshTargetingValues,
  getUSPConsent,
  hasUserOptOutCCPA,
  log,
  sentry,
} from '@repo/utils';
import { checkConfig, fallbackAction, fetchConfig, parseConfig, parsedFallback } from 'config';

import {
  CMP_EVENTS,
  DataObject,
  GDPRConsentStatus,
  GPPConsentStatus,
  REPORT_AUCTION,
  ThirdParty,
  USPConsentStatus,
} from '@repo/shared-types';
import { setupThirdPartyAPI } from 'third-party-apis/index';

import defaultFallbacks from 'config/fallback';
import { getParameters } from '@repo/utils';
import adServer from 'third-party-apis/ad-server';
import amClio from 'third-party-apis/am-clio';
import amazon from 'third-party-apis/amazon';
import euid from 'third-party-apis/euid';
import gpt from 'third-party-apis/gpt';
import ias from 'third-party-apis/ias';
import indexExchange from 'third-party-apis/index-exchange';
import liveRamp from 'third-party-apis/liveramp';
import prebid from 'third-party-apis/prebid';
import pubmatic from 'third-party-apis/pubmatic';
import pubx from 'third-party-apis/pubx';
import tmt from 'third-party-apis/the-media-trust';

import {
  accessObjectPath,
  checkAdUnitCountries,
  elementTapHandler,
  getEnv,
  getEnvDOMContentLoaded,
  performAdBlockTest,
  loadAdTool,
  timeData,
  TimeoutError,
  trimSlashes,
} from '@repo/utils';
import expandHeight from 'ad-features/expand-height';
import fullWidth from 'ad-features/full-width';
import multiFrame from 'ad-features/multi-frame';
import popOut from 'ad-features/pop-out';
import listenForAdFeatures from 'ad-features/post-message-listener';
import anchoredLogic from 'ad-framework/ad/anchored-logic';
import {
  Ad,
  AdDefinition,
  AdUnitCategory,
  AdUnitMode,
  AdUnitStatus,
  Slot,
  EVENTS,
  FEATURE,
  AuctionPayload,
  Payload,
  DataObjectStore,
  BordeauxMachineContext,
  ProvideBordeauxContextEvent,
  ProvideBordeauxDataEvent,
  AnyBordeauxEvent,
  RequestBordeauxContextEvent,
  RequestBordeauxDataEvent,
  SetHybridABTestTargetingEvent,
  SetRoadblockEvent,
  ACTIONS,
  GUARDS,
  STATES,
  METRICS,
  REPORT,
  REPORT_THIRD_PARTY_SCRIPT,
  ReportEvent,
  ThirdPartyReportEvent,
  FullAPISetupResults,
  AD_FEATURES_EVENTS,
  ThirdPartyEvent,
  API_EVENTS_IN,
  API_EVENTS_OUT,
  INCREMENTAL_ADS_EVENTS_IN,
  IncrementalAdsRoadblockStatusEvent,
  STANDARD_ADS_EVENTS_OUT,
  REFRESH_EVENTS,
} from '@repo/shared-types';
import initAutomaticRefresh from 'ad-framework/refresh/automatic';
import getRoadblockStatus from 'ad-framework/roadblock';
import getTargeting, { getHybridAbTestTargeting } from 'ad-framework/targeting';
import setupAPI from 'api';
import slotifyMachine from 'slotify';
import { mergeConfigs } from 'third-party-apis/merge-configs';
import userSyncPixels from '../user-sync/user-sync-config';
import * as actions from './actions';
import arbitraryEventEmitter from './actors/arbitrary-event-emitter';
import pageEventEmitter from './actors/page-event-emitter';
import readPageStyles from './actors/read-page-styles';
import waitForAllThirdParties from './actors/wait-for-all-third-parties';
import apiEvents from './api';
import assign, { assignParams } from './proxy/assign';
import enqueueActions from './proxy/enqueueActions';
import raise from './proxy/raise';
import sendTo from './proxy/send-to';
import * as report from './report';
import { ACTIONS_RECORD } from './report';
import { SommelierResponse } from 'config/sommelier-request/sommelier-response.types';
import { BordeauxConfig } from '@repo/shared-types/src/zod-schemas';
import { BordeauxMachineDefinition } from '@repo/shared-types/src/types/state/context.types';
import adManagerMachine from 'ad-framework/ad-manager';
import { adPreProcessor } from 'ad-framework/ad-manager/pre-process-ads';
import { getAdsToRefresh } from 'ad-framework/refresh/automatic/action';
import metrics from 'metrics/index';
import { EMPTY_OUTPUT } from '@repo/shared-types/src/types/ad-framework/empty-output';
import { createAdLabel } from 'ad-framework/ad/create-ad-label';
import {
  AD_MANAGER_EVENTS_IN,
  AD_MANAGER_EVENTS_OUT,
} from '@repo/shared-types/src/types/ad-framework/events.types';

const bordeauxMachine: BordeauxMachineDefinition = setup({
  types: {} as {
    context: BordeauxMachineContext;
    events: AnyBordeauxEvent;
  },
  actors: {
    arbitraryEventEmitter,
    elementTapHandler,
    initAutomaticRefresh,
    pageEventEmitter,
    getEnvDOMContentLoaded,
    setupAPI,
    fetchConfig,
    checkConfig,
    parseConfig,
    getGDPRConsentMachine,
    getUSPConsent,
    getGPPConsent,

    setupThirdPartyAPI,

    listenForAdFeatures,
    waitForAllThirdParties,
    readPageStyles,
    anchoredLogic,
    slotifyMachine,
    adManagerMachine,
    adPreProcessor,
  },
  guards: {
    [GUARDS.TIMING_COLLECTION_ENABLED]: ({ context }) => context.timing.enabled,
    [GUARDS.ERROR_IS_TIMEOUT]: ({ event }) =>
      event.type === REPORT.CONFIG_FAILURE && event.data.error instanceof TimeoutError,
    [GUARDS.DUPLICATE_SCRIPTS]: () => {
      const env = getEnv();
      return (
        (
          env.document.querySelectorAll(
            'script[src="https://bordeaux.futurecdn.net/bordeaux.js"]',
          ) || []
        ).length > 1
      );
    },
  },
  actions: {
    ...report.recordActions,
    ...report.reportActions,
    [ACTIONS.CREATE_API_MACHINE]: assign({
      externalApiMachine: ({ spawn }) => spawn('setupAPI'),
    }),
    [ACTIONS.CREATE_AD_FEATURE_MACHINE]: assign({
      adFeatureMachine: ({ spawn }) => spawn('listenForAdFeatures'),
    }),
    [ACTIONS.INITIALISE_FEATURES]: assign({
      featuresInitialised: true,
    }),

    [ACTIONS.CHECK_AD_BLOCK]: assign({
      adBlocked: await performAdBlockTest(),
    }),
    [ACTIONS.READ_QUERY_PARAMETERS]: assign({
      queryParameters: () => {
        const env = getEnv();
        const query = new URLSearchParams(env.location.search);
        return {
          country: query.get('force_locale') || query.get('force_country') || query.get('CC'),
          debugTool: query.get('debug_ads'),
          forceRoadblock: query.get('force_roadblock'),
          forceTargeting: query.get('force_targeting'),
          sommelierUrl: query.get('sommelier_url'),
          forcePLCDB: query.get('force_plc_db'),
          forceABTestControl: query.get('force_abtest_control'),
          forceABTestVariant: query.get('force_abtest_variant'),
          lrh: query.get('lrh'),
        };
      },
    }),
    [ACTIONS.READ_PAGE_PARAMETERS]: assign({
      pageParameters: getParameters,
    }),
    [ACTIONS.CREATE_SHAMEFUL_MACHINE]: assign({
      arbitraryEventEmitter: ({ context, spawn }) =>
        spawn('arbitraryEventEmitter', { input: { pageParameters: context.pageParameters } }),
    }),
    [ACTIONS.ASSIGN_INDEX_EXCHANGE_DEVICE_TYPE]: ({ context }) => {
      const env = getEnv();
      // Allows index exchange to pick the right kind of ads depending on the current device
      env.indexExchangeDeviceType = context.pageParameters.device;
    },
    [ACTIONS.ASSIGN_BORDEAUX_ADS_PROMISE]: () => {
      const env = getEnv();
      // Resolve if page is using Bordeaux ads
      env.bordeauxAds = Promise.resolve(true);
    },
    [ACTIONS.CHECK_MULTIPLE_SCRIPTS]: enqueueActions(({ check, enqueue }) => {
      if (check(GUARDS.DUPLICATE_SCRIPTS)) enqueue(REPORT.MULTIPLE_SCRIPTS);
    }),
    [ACTIONS.CREATE_ADTOOL_TAP_OPEN_MACHINE]: assign({
      adToolTapOpenMachine: ({ spawn }) =>
        spawn('elementTapHandler', {
          input: {
            element: document.body,
            fingers: 2,
            repetitions: 10,
          },
        }),
    }),
    [ACTIONS.CHECK_ADTOOL_PARAM]: enqueueActions(({ check, enqueue }) => {
      if (check(({ context }) => context.queryParameters.debugTool !== null))
        enqueue(ACTIONS.RAISE_OPEN_AD_TOOL);
    }),
    [ACTIONS.CREATE_AUTOMATIC_REFRESH_MACHINE]: assign({
      automaticRefreshMachine: ({ spawn }) => spawn('initAutomaticRefresh'),
    }),
    [ACTIONS.REPORT_IF_AD_BLOCKED]: enqueueActions(({ check, enqueue }) => {
      if (check(({ context }) => context.adBlocked)) enqueue(REPORT.AD_BLOCKED);
    }),

    [ACTIONS.USE_PARSED_FALLBACK_CONFIG]: assign({
      config: parsedFallback,
    }),
    [ACTIONS.USE_FALLBACK_CONFIG]: assign({
      sommelierResponse: fallbackAction,
    }),

    [ACTIONS.RAISE_OPEN_AD_TOOL]: raise({
      type: EVENTS.OPEN_AD_TOOL,
    }),
    [ACTIONS.THIRD_PARTIES_READY]: sendTo<BordeauxMachineContext['externalApiMachine']>(
      ({ context }) => context.externalApiMachine,
      {
        type: API_EVENTS_IN.THIRD_PARTIES_READY,
      },
    ),
    [ACTIONS.DECIDE_AVOIDANCE_DISTANCE]: assign({
      avoidanceDistance: ({ context }) => {
        const configAvoidanceDistance =
          context.config.placement.settings.adDensity?.avoidanceDistance;
        const apiAvoidanceDistanceConfig = context.deviceAvoidanceDistance;

        if (apiAvoidanceDistanceConfig) {
          const { device } = context.pageParameters;
          return apiAvoidanceDistanceConfig[device];
        }
        if (configAvoidanceDistance === undefined) {
          return context.avoidanceDistance;
        }
        return configAvoidanceDistance;
      },
    }),
    [ACTIONS.DECIDE_TEST_ACTIVATION_DISTANCE]: actions.decideActivationDistanceTest,
    [ACTIONS.DECIDE_TEST_PUBX]: actions.decidePubxAbTest,
    [ACTIONS.DECIDE_TEST_ID_SERVICE_ACTIVATION]: actions.decideIdsActivationTest,
    [ACTIONS.DECIDE_THIRD_PARTY_CONFIG]: assign({
      thirdPartyApiConfig: ({ context }) =>
        mergeConfigs(context.config.thirdPartyAPIConfig, context.thirdPartyApiConfigOverrides),
    }),
    [ACTIONS.DECIDE_LIVE_INTENT_USER_SYNC]: assign({
      liveIntentUserSyncEnabled: ({ context }) =>
        context.thirdPartyApiConfig.liveIntent.enabled && Math.random() < 0.9,
    }),
    [ACTIONS.DECIDE_PAGE_ADUNIT_PATH]: assign({
      pageAdUnitPath: ({ context }) =>
        `/${[
          context.config.placement.siteAdUnitPath,
          ...(!context.pageCategory ? [] : [context.pageCategory]),
          context.config.placement.adUnitPath,
        ]
          .map(trimSlashes)
          .join('/')}`,
    }),
    [ACTIONS.INITIALISE_SENTRY]: report.initialiseSentry,
    [ACTIONS.SEND_AB_TEST_TO_FREYR]: actions.handleHybridAbTestsIfAny,
    [ACTIONS.SETUP_CUSTOM_ACTIVATIONS]: actions.setupCustomActivations,
    [ACTIONS.DECIDE_REFRESH_TIME]: actions.decideRefreshTime,
    [ACTIONS.SETUP_USER_SYNC]: actions.setupUserSync,
    [ACTIONS.SETUP_AD_MANAGER]: assign({
      adManager: ({ spawn, context: { hybridId, uspConsent } }) =>
        spawn('adManagerMachine', {
          id: 'adManager',
          input: {
            privacySettings: { restrictDataProcessing: hasUserOptOutCCPA(uspConsent) },
            ...(hybridId
              ? {
                  publisherProvidedId: hybridId,
                }
              : {}),
          },
        }),
    }),
    [ACTIONS.HANDLE_ERROR]: actions.handleError,
    [ACTIONS.ASSIGN_LIVE_INTENT_USER_SYNC_TARGETING]: enqueueActions(({ check, enqueue }) => {
      if (
        check(
          ({ context }): boolean =>
            context.thirdPartyResults[ThirdParty.PREBID].config.enabled &&
            context.thirdPartyResults[ThirdParty.PREBID].success,
        )
      ) {
        if (check(({ context }) => context.liveIntentUserSyncEnabled)) {
          enqueue(
            assign({
              pageTargeting: ({ context }): BordeauxMachineContext['pageTargeting'] => ({
                ...context.pageTargeting,
                'li-module-enabled': ['on'],
              }),
            }),
          );
        } else {
          enqueue(
            assign({
              pageTargeting: ({ context }): BordeauxMachineContext['pageTargeting'] => ({
                ...context.pageTargeting,
                'li-module-enabled': ['off'],
              }),
            }),
          );
        }
      }
    }),
    [ACTIONS.STORE_HYBRID_TEST_SESSIONS]: actions.storeHybridTestSessions,
    [ACTIONS.DECIDE_TEST_AUCTION_TIMEOUTS]: actions.decideTestAuctionTimeouts,

    [ACTIONS.DECIDE_ROADBLOCK_INCREMENTALS]: assign({
      roadblockIncrementals: ({
        context: {
          roadblockIncrementalChooser,
          adUnits: { incremental: adDefinitions },
        },
      }) => {
        const incrementalChooser = roadblockIncrementalChooser;
        return adDefinitions.filter((ad: AdDefinition): boolean => {
          if (incrementalChooser) {
            const apiChooserOverride = incrementalChooser(ad);
            if (apiChooserOverride === false) return false;
            if (apiChooserOverride === true) return true;
          }
          return Boolean(ad.roadblockIncremental);
        });
      },
    }),
    [ACTIONS.DECIDE_VALID_ADUNITS]: assign({
      adUnits: ({
        context: {
          pageParameters: { country },
          config: {
            placement: { adUnits },
          },
        },
      }) => ({
        standard: adUnits.standard.filter(checkAdUnitCountries(country.toLowerCase())),
        incremental: adUnits.incremental.filter(checkAdUnitCountries(country.toLowerCase())),
      }),
    }),
    [ACTIONS.HIDE_ANCHORED]: ({ context }) => {
      context.ads
        .getValues()
        .filter(ad => ad.getProperty('mode') === AdUnitMode.ANCHORED)
        .forEach(ad => {
          const element = ad.getProperty('elements')?.outerContainer;
          if (element) {
            element.style.transform = 'translate(0, 100%)';
            element.style.transition = '.5s';
          }
        });
    },
    [ACTIONS.SHOW_ANCHORED]: ({ context }) => {
      context.ads
        .getValues()
        .filter(ad => ad.getProperty('mode') === AdUnitMode.ANCHORED)
        .forEach(ad => {
          const element = ad.getProperty('elements')?.outerContainer;
          if (element) {
            element.style.transform = 'translate(0, 0)';
            element.style.transition = '.5s';
          }
        });
    },
    [ACTIONS.AD_MANAGER_AUCTION]: sendTo('adManager', ({}, ads: Array<DataObject<Ad>>) => ({
      type: AD_MANAGER_EVENTS_IN.AUCTION,
      data: {
        ads: ads.sort((a, b) => a.getProperty('requestOrder') - b.getProperty('requestOrder')),
      },
    })),
    [ACTIONS.UPDATE_AD_MANAGER_TARGETING]: sendTo('adManager', ({ context }) => ({
      type: AD_MANAGER_EVENTS_IN.SET_TARGETING,
      data: {
        targeting: getTargeting(context),
      },
    })),
    [ACTIONS.PRE_PROCESS_AUCTION]: assignParams({
      adPreProcessors: ({ spawn, context: { adPreProcessors } }, auctionId: number) => ({
        ...adPreProcessors,
        [auctionId]: spawn('adPreProcessor', {
          input: ({
            context,
            event: {
              data: { auctionId, ads },
            },
          }) => ({
            context,
            auctionId,
            ads,
          }),
        }),
      }),
    }),
  },
}).createMachine({
  id: 'bordeaux',
  initial: STATES.INITIALISING,
  context: {
    config: {} as BordeauxConfig,
    fallbackResponses: defaultFallbacks,
    sommelierResponse: {} as SommelierResponse,
    slots: new DataObjectStore<Slot>(),
    ads: new DataObjectStore<Ad>(),
    adUnits: { incremental: [], standard: [] },
    adManager: {} as BordeauxMachineContext['adManager'],
    adPreProcessors: {},
    anchoredRefreshDisabled: false,
    timing: {
      payload: {} as Payload,
      auctionPayloads: {} as Record<number, AuctionPayload>,
      endpoint: `https://eventsproxy.gargantuan.futureplc.com/future.adtech.bordeaux.v1.AdRequestCompletedEvent`,
      unsentAuctions: [],
      sampleRate: 1,
      enabled: false,
    },
    thirdPartyMachines: {} as BordeauxMachineContext['thirdPartyMachines'],
    thirdPartyResults: {} as FullAPISetupResults,
    metrics: {
      [METRICS.THIRD_PARTY_SCRIPTS]: {},
      [METRICS.AUCTIONS]: {},
    },
    auctions: {},
    gdprConsent: {
      done: false,
      askedForConsent: false,
      consent: null,
      status: GDPRConsentStatus.NOT_APPLICABLE,
      hasEnoughConsentForAuction: false,
    },
    uspConsent: {
      done: false,
      askedForConsent: false,
      consent: null,
      ccpaApplies: false,
      status: USPConsentStatus.NOT_APPLICABLE,
    },
    gppConsent: {
      done: false,
      consent: null,
      status: GPPConsentStatus.NOT_APPLICABLE,
    },
    experimentId: '',
    hybridABTestTargeting: [],
    hybridId: '',
    gaSessionId: cookies.gaSessionId(),
    arbitraryEventEmitter: {} as BordeauxMachineContext['arbitraryEventEmitter'],
    adFeatureMachine: {} as BordeauxMachineContext['adFeatureMachine'],
    pageEventEmitter: {} as BordeauxMachineContext['pageEventEmitter'],
    cftParameters: {
      labelName: 'UNDECIDED',
    } as BordeauxMachineContext['cftParameters'],
    pageParameters: {} as BordeauxMachineContext['pageParameters'],
    queryParameters: {} as BordeauxMachineContext['queryParameters'],
    adToolTapOpenMachine: {} as BordeauxMachineContext['adToolTapOpenMachine'],
    automaticRefreshMachine: {} as BordeauxMachineContext['automaticRefreshMachine'],
    externalApiMachine: {} as BordeauxMachineContext['externalApiMachine'],
    pageStyleConstants: {} as BordeauxMachineContext['pageStyleConstants'],
    anchoredMachine: {} as BordeauxMachineContext['anchoredMachine'],
    adBlocked: false,
    isRoadblock: null,
    roadblockIncrementals: [],
    loadGptExternallyPromise: ((): BordeauxMachineContext['loadGptExternallyPromise'] => {
      let resolve;
      const promise = new Promise<boolean>(res => {
        resolve = res;
      });
      return {
        promise,
        resolve,
      };
    })(),
    userSyncPixels,
    // API
    overrideCompanionBounds: {},
    unrefreshableNames: [],
    unrefreshableLineItems: [],
    unrefreshableOrders: [],
    unrefreshableAdvertisers: [],
    unrefreshableStatuses: [AdUnitStatus.PENDING],
    unrefreshableModes: [AdUnitMode.OOP],
    unrefreshableCategories: [AdUnitCategory.SPONSORED_POST],
    adToolVersion: '',
    loadGptExternally: false,
    prebidAnalyticsEnabled: false,
    auctionTimeouts: undefined,
    automaticDynamic: true,
    pageCategory: undefined,
    pageTemplate: undefined,
    refreshTime: 0,
    roadblockIncrementalCaps: null,
    roadblockIncrementalChooser: null,
    activationDistance: 1200,
    deviceAvoidanceDistance: undefined,
    avoidanceDistance: 400,
    thirdPartyApiConfig: {} as BordeauxMachineContext['thirdPartyApiConfig'],
    thirdPartyApiConfigOverrides: {},
    pageTargeting: {},
    pageAdUnitPath: 'undecided',
    features: {
      [FEATURE.ADS_INCREMENTAL]: true,
      [FEATURE.ADS_STANDARD]: true,
      [FEATURE.ADS_REFRESH]: true,
    },
    featuresInitialised: false,
    liveIntentUserSyncEnabled: false,
  },
  states: {
    [STATES.INITIALISING]: {
      entry: [
        ACTIONS.CREATE_AD_FEATURE_MACHINE,
        assign({
          timing: ({ context }) => ({
            ...context.timing,
            enabled: Math.random() < context.timing.sampleRate,
          }),
        }),
        assign({
          pageEventEmitter: ({ spawn }) => spawn('pageEventEmitter'),
        }),
      ],
      invoke: {
        src: 'getEnvDOMContentLoaded',
        onDone: {
          actions: raise(
            ({ event }): ReportEvent<REPORT.CONTENT_LOAD> => ({
              type: REPORT.CONTENT_LOAD,
              data: { time: event.output },
            }),
          ),
          target: STATES.SCRAPING_PARAMATERS,
        },
        onError: STATES.ERROR,
      },
    },
    [STATES.SCRAPING_PARAMATERS]: {
      entry: [
        ACTIONS.CHECK_AD_BLOCK,
        ACTIONS.READ_QUERY_PARAMETERS,
        ACTIONS.READ_PAGE_PARAMETERS,
        ACTIONS_RECORD.INITIALISE_PAYLOAD,
        ACTIONS.CREATE_SHAMEFUL_MACHINE,
        ACTIONS.ASSIGN_INDEX_EXCHANGE_DEVICE_TYPE,
        ACTIONS.ASSIGN_BORDEAUX_ADS_PROMISE,
        ACTIONS.CHECK_MULTIPLE_SCRIPTS,
        ACTIONS.CREATE_ADTOOL_TAP_OPEN_MACHINE,
        ACTIONS.CHECK_ADTOOL_PARAM,
        ACTIONS.CREATE_AUTOMATIC_REFRESH_MACHINE,
        ACTIONS.REPORT_IF_AD_BLOCKED,
        ACTIONS.CREATE_API_MACHINE,
      ],
      on: {
        [EVENTS.SET_EXTERNAL_API]: {
          actions: [
            ({ event }): void => {
              const env = getEnv();
              // Casting to any to allow us to attach to the env without types which would
              // cause circular reference warnings.
              (env as any).bordeaux = event.data; // eslint-disable-line @typescript-eslint/no-explicit-any
              (env as any).bdx = event.data; // eslint-disable-line @typescript-eslint/no-explicit-any
            },
            sendTo(({ context }) => context.externalApiMachine, {
              type: API_EVENTS_IN.API_READY,
            }),
          ],
        },
        [API_EVENTS_OUT.COMMANDS_RUN]: [
          {
            guard: ({
              event: {
                data: { initialised },
              },
            }) => initialised,
            target: STATES.FETCHING_CONFIG,
          },
          {
            target: STATES.WAIT_FOR_INITIALISATION,
          },
        ],
      },
    },
    [STATES.WAIT_FOR_INITIALISATION]: {
      on: {
        [API_EVENTS_OUT.INITIALISE]: {
          target: STATES.FETCHING_CONFIG,
        },
      },
    },
    [STATES.FETCHING_CONFIG]: {
      entry: [
        REPORT.FRAMEWORK_REQUEST,
        ACTIONS.INITIALISE_SENTRY,
        ACTIONS.INITIALISE_FEATURES,
        REPORT.CONFIG_REQUEST,
      ],
      invoke: {
        src: 'fetchConfig',
        input: ({ context }) => ({
          pageTemplate: context.pageTemplate,
          gaSessionId: context.gaSessionId,
        }),
        onDone: {
          actions: [
            REPORT.CONFIG_LOAD,
            assign({
              sommelierResponse: ({ event }): SommelierResponse => event.output,
            }),
          ],
          target: STATES.CHECKING_CONFIG,
        },
        onError: {
          actions: [
            raise(
              ({ event }): ReportEvent<REPORT.CONFIG_FAILURE> => ({
                type: REPORT.CONFIG_FAILURE,
                data: {
                  time: timeData(),
                  error: event.error as Error,
                },
              }),
            ),
            ACTIONS.USE_FALLBACK_CONFIG,
          ],
          target: STATES.PARSING_CONFIG,
        },
      },
    },
    [STATES.CHECKING_CONFIG]: {
      invoke: {
        src: 'checkConfig',
        input: ({ context }) => ({ sommelierResponse: context.sommelierResponse }),
        onDone: STATES.PARSING_CONFIG,
        onError: {
          actions: [REPORT.CONFIG_EMPTY, ACTIONS.USE_FALLBACK_CONFIG],
          target: STATES.PARSING_CONFIG,
        },
      },
    },
    [STATES.PARSING_CONFIG]: {
      invoke: {
        src: 'parseConfig',
        input: ({ context }) => ({
          sommelierResponse: context.sommelierResponse,
        }),
        onDone: {
          actions: [
            assign({
              config: ({ event }): BordeauxConfig => event.output,
            }),
            REPORT.CONFIG_SUCCESS,
            ACTIONS.SEND_AB_TEST_TO_FREYR,
            ACTIONS.DECIDE_TEST_ACTIVATION_DISTANCE,
            ACTIONS.DECIDE_AVOIDANCE_DISTANCE,
            ACTIONS.DECIDE_TEST_PUBX,
            ACTIONS.DECIDE_TEST_ID_SERVICE_ACTIVATION,
            ACTIONS.DECIDE_THIRD_PARTY_CONFIG,
            ACTIONS.DECIDE_PAGE_ADUNIT_PATH,
            ACTIONS.DECIDE_VALID_ADUNITS,
            ACTIONS.DECIDE_ROADBLOCK_INCREMENTALS,
            sendTo<BordeauxMachineContext['externalApiMachine']>(
              ({ context }) => context.externalApiMachine,
              {
                type: API_EVENTS_IN.CONFIG_READY,
              },
            ),
            ACTIONS.STORE_HYBRID_TEST_SESSIONS,
            ACTIONS.DECIDE_TEST_AUCTION_TIMEOUTS,
          ],
          target: STATES.RETRIEVING_CONSENT,
        },
        onError: {
          actions: [
            ACTIONS.USE_PARSED_FALLBACK_CONFIG,
            REPORT.CONFIG_PARSE_FAILURE,
            ACTIONS.SEND_AB_TEST_TO_FREYR,
            ACTIONS.DECIDE_AVOIDANCE_DISTANCE,
            ACTIONS.DECIDE_THIRD_PARTY_CONFIG,
            ACTIONS.DECIDE_PAGE_ADUNIT_PATH,
            ACTIONS.DECIDE_VALID_ADUNITS,
            ACTIONS.DECIDE_ROADBLOCK_INCREMENTALS,
            sendTo<BordeauxMachineContext['externalApiMachine']>(
              ({ context }) => context.externalApiMachine,
              {
                type: API_EVENTS_IN.CONFIG_READY,
              },
            ),
          ],
          target: STATES.RETRIEVING_CONSENT,
        },
      },
    },
    [STATES.RETRIEVING_CONSENT]: {
      entry: [
        ACTIONS.SETUP_CUSTOM_ACTIVATIONS,
        ACTIONS.DECIDE_REFRESH_TIME,
        REPORT.CONSENT_REQUEST,
      ],
      invoke: [
        {
          src: 'getUSPConsent',
          onDone: {
            actions: [
              assign({
                uspConsent: ({ event }) => event.output,
              }),
              assign({
                pageTargeting: ({ context, event }) => ({
                  ...context.pageTargeting,
                  _usp_status: event.output.status,
                }),
              }),
              raise({
                type: EVENTS.CONSENT_DONE,
              }),
            ],
          },
          onError: {
            target: STATES.ERROR,
          },
        },
        {
          src: 'getGDPRConsentMachine',
          onDone: {
            actions: [
              REPORT.CONSENT_SUCCESS,
              assign({
                gdprConsent: ({ event }) => event.output,
              }),
              assign({
                pageTargeting: ({ context, event }) => ({
                  ...context.pageTargeting,
                  _gdpr_status: event.output.status,
                }),
              }),
              raise({
                type: EVENTS.CONSENT_DONE,
              }),
            ],
          },
          onError: {
            actions: REPORT.CONSENT_FAILURE,
            target: STATES.ERROR,
          },
        },
        {
          src: 'getGPPConsent',
          onDone: {
            actions: [
              assign({
                gppConsent: ({ event }) => event.output,
              }),
              assign({
                pageTargeting: ({ context, event }) => ({
                  ...context.pageTargeting,
                  _gpp_status: event.output.status,
                }),
              }),
              raise({
                type: EVENTS.CONSENT_DONE,
              }),
            ],
          },
          onError: {
            target: STATES.ERROR,
          },
        },
      ],
      on: {
        [CMP_EVENTS.PENDING]: {
          actions: REPORT.CONSENT_PENDING,
        },
        [CMP_EVENTS.LOADED]: {
          actions: REPORT.CONSENT_LOADED,
        },
        [CMP_EVENTS.MOCKED]: {
          actions: REPORT.CONSENT_MOCKED,
        },
        [EVENTS.CONSENT_DONE]: {
          guard: ({ context }) => context.uspConsent.done && context.gdprConsent.done,
          target: STATES.LOADING_THIRD_PARTIES,
        },
      },
    },
    [STATES.LOADING_THIRD_PARTIES]: {
      entry: [
        REPORT.THIRD_PARTY_REQUEST,
        ACTIONS.DECIDE_LIVE_INTENT_USER_SYNC,
        assign({
          thirdPartyMachines: ({ spawn, context }) => {
            const bordeaux: Pick<
              BordeauxMachineContext,
              | 'config'
              | 'liveIntentUserSyncEnabled'
              | 'gdprConsent'
              | 'loadGptExternallyPromise'
              | 'loadGptExternally'
              | 'thirdPartyApiConfig'
              | 'sommelierResponse'
              | 'pageTargeting'
            > = {
              config: context.config,
              gdprConsent: context.gdprConsent,
              liveIntentUserSyncEnabled: context.liveIntentUserSyncEnabled,
              thirdPartyApiConfig: context.thirdPartyApiConfig,
              loadGptExternallyPromise: context.loadGptExternallyPromise,
              loadGptExternally: context.loadGptExternally,
              sommelierResponse: context.sommelierResponse,
              pageTargeting: context.pageTargeting,
            };
            return {
              [ThirdParty.AD_SERVER]: spawn('setupThirdPartyAPI', {
                id: `ThirdParty-${ThirdParty.AD_SERVER}`,
                input: { bordeaux, thirdPartyMethods: adServer },
              }),
              [ThirdParty.AM_CLIO]: spawn('setupThirdPartyAPI', {
                id: `ThirdParty-${ThirdParty.AM_CLIO}`,
                input: { bordeaux, thirdPartyMethods: amClio },
              }),
              [ThirdParty.AMAZON]: spawn('setupThirdPartyAPI', {
                id: `ThirdParty-${ThirdParty.AMAZON}`,
                input: { bordeaux, thirdPartyMethods: amazon },
              }),
              [ThirdParty.EUID]: spawn('setupThirdPartyAPI', {
                id: `ThirdParty-${ThirdParty.EUID}`,
                input: { bordeaux, thirdPartyMethods: euid },
              }),
              [ThirdParty.GPT]: spawn('setupThirdPartyAPI', {
                id: `ThirdParty-${ThirdParty.GPT}`,
                input: { bordeaux, thirdPartyMethods: gpt },
              }),
              [ThirdParty.IAS]: spawn('setupThirdPartyAPI', {
                id: `ThirdParty-${ThirdParty.IAS}`,
                input: { bordeaux, thirdPartyMethods: ias },
              }),
              [ThirdParty.INDEX_EXCHANGE]: spawn('setupThirdPartyAPI', {
                id: `ThirdParty-${ThirdParty.INDEX_EXCHANGE}`,
                input: { bordeaux, thirdPartyMethods: indexExchange },
              }),
              [ThirdParty.LIVE_RAMP]: spawn('setupThirdPartyAPI', {
                id: `ThirdParty-${ThirdParty.LIVE_RAMP}`,
                input: { bordeaux, thirdPartyMethods: liveRamp },
              }),
              [ThirdParty.PREBID]: spawn('setupThirdPartyAPI', {
                id: `ThirdParty-${ThirdParty.PREBID}`,
                input: { bordeaux, thirdPartyMethods: prebid },
              }),
              [ThirdParty.PUBMATIC]: spawn('setupThirdPartyAPI', {
                id: `ThirdParty-${ThirdParty.PUBMATIC}`,
                input: { bordeaux, thirdPartyMethods: pubmatic },
              }),
              [ThirdParty.PUBX]: spawn('setupThirdPartyAPI', {
                id: `ThirdParty-${ThirdParty.PUBX}`,
                input: { bordeaux, thirdPartyMethods: pubx },
              }),
              [ThirdParty.TMT]: spawn('setupThirdPartyAPI', {
                id: `ThirdParty-${ThirdParty.TMT}`,
                input: { bordeaux, thirdPartyMethods: tmt },
              }),
            } as BordeauxMachineContext['thirdPartyMachines'];
          },
        }),
      ],
      invoke: {
        src: 'waitForAllThirdParties',
        input: ({ context }) => ({ thirdPartyMachines: context.thirdPartyMachines }),
        onDone: {
          actions: [
            REPORT.THIRD_PARTY_SUCCESS,
            assign({
              thirdPartyResults: ({ event }) => event.output,
            }),
            ACTIONS.ASSIGN_LIVE_INTENT_USER_SYNC_TARGETING,
            ACTIONS.THIRD_PARTIES_READY,
          ],
          target: STATES.DECIDING_PAGE_STYLE_CONSTANTS,
        },
        onError: STATES.ERROR,
      },
      on: {
        [ThirdPartyEvent.THIRD_PARTY_REQUEST]: {
          actions: raise(
            ({ event }): ThirdPartyReportEvent<REPORT_THIRD_PARTY_SCRIPT.REQUEST> => ({
              type: REPORT_THIRD_PARTY_SCRIPT.REQUEST,
              data: {
                thirdParty: event.data,
                time: timeData(),
              },
            }),
          ),
        },
        [ThirdPartyEvent.THIRD_PARTY_SUCCESS]: {
          actions: raise(
            ({ event }): ThirdPartyReportEvent<REPORT_THIRD_PARTY_SCRIPT.SUCCESS> => ({
              type: REPORT_THIRD_PARTY_SCRIPT.SUCCESS,
              data: {
                thirdParty: event.data,
                time: timeData(),
              },
            }),
          ),
        },
        [ThirdPartyEvent.THIRD_PARTY_TIMEOUT]: {
          actions: raise(
            ({ event }): ThirdPartyReportEvent<REPORT_THIRD_PARTY_SCRIPT.TIMEOUT> => ({
              type: REPORT_THIRD_PARTY_SCRIPT.TIMEOUT,
              data: {
                thirdParty: event.data,
                time: timeData(),
              },
            }),
          ),
        },
        [ThirdPartyEvent.THIRD_PARTY_FAILURE]: {
          actions: raise(
            ({ event }): ThirdPartyReportEvent<REPORT_THIRD_PARTY_SCRIPT.FAILURE> => ({
              type: REPORT_THIRD_PARTY_SCRIPT.FAILURE,
              data: {
                thirdParty: event.data,
                time: timeData(),
              },
            }),
          ),
        },
      },
    },
    [STATES.DECIDING_PAGE_STYLE_CONSTANTS]: {
      invoke: {
        src: 'readPageStyles',
        onDone: {
          actions: assign({
            pageStyleConstants: ({ event }) => event.output,
          }),
          target: STATES.HANDLING_SLOTS,
        },
      },
    },

    [STATES.HANDLING_SLOTS]: {
      entry: [
        ACTIONS.SETUP_USER_SYNC,
        raise(
          ({ context }): SetHybridABTestTargetingEvent => ({
            type: EVENTS.SET_HYBRID_ABTEST_TARGETING,
            data: getHybridAbTestTargeting(context),
          }),
        ),
        assign({
          anchoredMachine: ({ spawn, context }) =>
            spawn('anchoredLogic', {
              input: {
                slots: context.slots,
                ads: context.ads,
                config: context.config,
                pageParameters: context.pageParameters,
              },
            }),
        }),
        ACTIONS.SETUP_AD_MANAGER,
      ],
      invoke: {
        src: 'slotifyMachine',
        id: 'slotify',
        input: ({ context }) => context,
        onError: STATES.ERROR,
      },
      on: {
        [STANDARD_ADS_EVENTS_OUT.FAILURE]: {
          target: STATES.ERROR,
        },
      },
    },
    [STATES.ERROR]: {
      entry: ACTIONS.HANDLE_ERROR,
    },
  },
  on: {
    ...report.reportEvents,
    ...apiEvents,
    [AD_FEATURES_EVENTS.FULL_WIDTH]: {
      actions: fullWidth,
    },
    [AD_FEATURES_EVENTS.POP_OUT]: {
      actions: popOut,
    },
    [AD_FEATURES_EVENTS.MULTI_FRAME]: {
      actions: multiFrame,
    },
    [AD_FEATURES_EVENTS.EXPAND_HEIGHT]: {
      actions: expandHeight,
    },
    [EVENTS.SET_HYBRID_ABTEST_TARGETING]: {
      actions: [
        assign({
          hybridABTestTargeting: ({ event }) => event.data,
        }),
        ACTIONS_RECORD.HYBRID_ABTEST_TARGETING,
      ],
    },
    [EVENTS.SET_HYBRID_ID]: {
      actions: [
        assign({
          hybridId: ({ event }) => event.data,
        }),
        ACTIONS_RECORD.HYBRID_ID,
        sendTo(({ context }) => context.externalApiMachine, {
          type: API_EVENTS_IN.HYBRID_ID_READY,
        }),
        actions.sendUserIdsToFreyr,
      ],
    },
    [EVENTS.SET_CFT_PARAMETERS]: {
      actions: [
        assign({
          cftParameters: ({ event }) => event.data,
        }),
        ACTIONS_RECORD.CFT_PARAMETERS,
      ],
    },
    [EVENTS.PAGE_UNLOAD]: {
      actions: enqueueActions(({ enqueue, check }) => {
        if (check(GUARDS.TIMING_COLLECTION_ENABLED)) enqueue(report.pageUnload);
      }),
    },
    [EVENTS.PAGE_LOAD]: {
      actions: report.pageLoad,
    },
    [EVENTS.OPEN_AD_TOOL]: {
      actions: loadAdTool,
    },
    [EVENTS.CHECK_ROADBLOCK_STATUS]: {
      actions: enqueueActions(({ check, enqueue }) => {
        if (check(({ context }) => context.isRoadblock === null)) {
          if (check(({ context }) => getRoadblockStatus(context) !== null)) {
            enqueue(
              raise(
                ({ context }): SetRoadblockEvent => ({
                  type: EVENTS.SET_ROADBLOCK_STATUS,
                  data: getRoadblockStatus(context) as boolean,
                }),
              ),
            );
          }
        }
      }),
    },
    [EVENTS.SET_ROADBLOCK_STATUS]: {
      actions: [
        assign({
          isRoadblock: ({ event }) => event.data,
        }),
        actions.customVideoBehaviourAction,
        sendTo<BordeauxMachineContext['automaticRefreshMachine'], SetRoadblockEvent>(
          ({ context }) => context.automaticRefreshMachine,
          ({ event }) => ({
            type: REFRESH_EVENTS.SET_ROADBLOCK,
            data: event.data,
          }),
        ),
        sendTo(
          'slotify',
          ({ event }): IncrementalAdsRoadblockStatusEvent => ({
            type: INCREMENTAL_ADS_EVENTS_IN.ROADBLOCK_STATUS,
            data: event.data,
          }),
        ),
        sendTo(({ context }) => context.externalApiMachine, {
          type: API_EVENTS_IN.ROADBLOCK_READY,
        }),
      ],
    },
    [EVENTS.AUTOMATIC_REFRESH]: [
      {
        guard: ({ event: { data: ads } }) => ads.length !== 0,
        actions: [
          ({ event: { data: ads } }) => {
            ads.forEach(ad => {
              const targeting = ad.getProperty('targeting');
              ad.update({
                targeting: {
                  ...targeting,
                  ...getRefreshTargetingValues(ad, false),
                },
                inView: false,
                viewedTime: 0,
              });
            });
          },
          sendTo('adManager', ({ event: { data: ads } }) => ({
            type: AD_MANAGER_EVENTS_IN.REFRESH,
            data: { ads },
          })),
        ],
      },
    ],
    [EVENTS.TRIGGER_AUTOMATIC_REFRESH]: {
      actions: [
        ({ context: { ads } }) => {
          ads.getValues().forEach(ad => {
            if (ad.getProperty('inView')) {
              const viewedTime = ad.getProperty('viewedTime') || 0;
              ad.update({ viewedTime: viewedTime + 1 });
            }
          });
        },
        raise(({ context }) => ({
          type: EVENTS.AUTOMATIC_REFRESH,
          data: getAdsToRefresh(context),
        })),
      ],
    },

    [EVENTS.REQUEST_BORDEAUX_DATA]: {
      actions: sendTo<BordeauxMachineContext['externalApiMachine'], RequestBordeauxDataEvent>(
        ({ context }) => context.externalApiMachine,
        ({ context, event }): ProvideBordeauxDataEvent => ({
          type: EVENTS.PROVIDE_BORDEAUX_DATA,
          data: {
            value: accessObjectPath(context, event.data.property),
            requestId: event.data.requestId,
          },
        }),
      ),
    },

    [EVENTS.REQUEST_BORDEAUX_CONTEXT]: {
      actions: sendTo<BordeauxMachineContext['externalApiMachine'], RequestBordeauxContextEvent>(
        ({ context }) => context.externalApiMachine,
        ({ context, event }): ProvideBordeauxContextEvent => ({
          type: EVENTS.PROVIDE_BORDEAUX_CONTEXT,
          data: {
            value: context,
            requestId: event.data.requestId,
          },
        }),
      ),
    },
    [EVENTS.HIDE_ANCHORED_ADS]: [
      {
        guard: ({ event }) => Boolean(event.data?.infiniteScrollIntersection),
        actions: [
          ACTIONS.HIDE_ANCHORED,
          assign({
            anchoredRefreshDisabled: true,
          }),
        ],
      },
      {
        actions: [ACTIONS.HIDE_ANCHORED],
      },
    ],
    [EVENTS.SHOW_ANCHORED_ADS]: [
      {
        guard: ({ event }) => Boolean(event.data?.infiniteScrollIntersection),
        actions: [
          ACTIONS.SHOW_ANCHORED,
          assign({
            anchoredRefreshDisabled: false,
          }),
        ],
      },
      {
        actions: [ACTIONS.SHOW_ANCHORED],
      },
    ],
    [AD_MANAGER_EVENTS_OUT.SLOT_VISIBILITY_CHANGED]: {
      actions: ({
        context: { ads },
        event: {
          data: { adId, inViewPercentage },
        },
      }) => {
        const ad = ads.getValues().find(ad => ad.getProperty('id') === adId);
        if (!ad) {
          return;
        }

        ad.update({ inView: inViewPercentage >= 50 });
        ad.update({ inViewport: inViewPercentage > 0 });
        ad.update({ inView75Percent: inViewPercentage > 75 });
      },
    },
    [AD_MANAGER_EVENTS_OUT.SLOT_ON_LOAD]: {
      actions: [
        ({
          context: { ads },
          event: {
            data: { adId },
          },
        }) => {
          const ad = ads.getValues().find(ad => ad.getProperty('id') === adId);
          if (!ad) {
            return;
          }
          sentry.breadcrumb({
            category: 'advert',
            message: `Ad slotOnLoad - ${ad.getProperty('name')}`,
          });
          metrics.mark(`Ad slotOnLoad - ${ad.getProperty('name')}`);
          const auctionId = ad.getProperty('auctionId');
          if (!auctionId) {
            log.error('Error handling GPT slot loaded, auctionId is undefined.');
            return;
          }
        },
        raise(
          ({
            context: { ads },
            event: {
              data: { adId },
            },
          }) => ({
            type: REPORT_AUCTION.AD_LOAD,
            data: {
              time: timeData(),
              auction: ads
                .getValues()
                .find(ad => ad.getProperty('id') === adId)!
                .getProperty('auctionId')!,
            },
          }),
        ),
      ],
    },
    [AD_MANAGER_EVENTS_OUT.SLOT_IMPRESSION_VIEWABLE]: {
      actions: ({
        context: { ads },
        event: {
          data: { adId },
        },
      }) => {
        const ad = ads.getValues().find(ad => ad.getProperty('id') === adId);
        if (!ad) {
          return;
        }
        ad.update({ viewed: true, status: AdUnitStatus.VIEWED });
      },
    },
    [AD_MANAGER_EVENTS_OUT.SLOT_RENDER_ENDED]: {
      actions: [
        ({
          event: {
            data: { adId, gptOutput },
          },
          context: { ads },
        }) => {
          const env = getEnv();
          const ad = ads.getValues().find(ad => ad.getProperty('id') === adId);
          if (!ad) {
            return;
          }
          sentry.breadcrumb({
            category: 'advert',
            message: `Ad slotRenderEnded - ${ad.getProperty('name')}`,
          });
          metrics.mark(`Ad slotRenderEnded - ${ad.getProperty('name')}`);

          if (gptOutput !== EMPTY_OUTPUT) {
            const loadTime = env.performance.now();
            ad.update({
              status: AdUnitStatus.DELIVERED,
              loadTime,
              gptOutput,
            });
            const adLabel = ad.getProperty('label');
            if (adLabel) {
              const adLabelElement = createAdLabel(ad.getProperty('id'), adLabel);
              const adElement = ad.getProperty('elements')?.element;

              if (adElement) {
                adElement.insertBefore(adLabelElement, adElement.firstChild);
              }
            }
          } else {
            ad.update({ status: AdUnitStatus.UNDELIVERED });
          }
        },
        raise({
          type: EVENTS.CHECK_ROADBLOCK_STATUS,
        }),
      ],
    },
    [EVENTS.REQUEST_AUCTION]: {
      actions: {
        type: ACTIONS.AD_MANAGER_AUCTION,
        params: ({
          event: {
            data: { ads },
          },
        }) => ads,
      },
    },
    [AD_MANAGER_EVENTS_OUT.AUCTION_CREATED]: [
      {
        guard: ({
          context: {
            gdprConsent: { hasEnoughConsentForAuction },
          },
        }) => hasEnoughConsentForAuction,
        actions: [
          ACTIONS.UPDATE_AD_MANAGER_TARGETING,
          {
            type: ACTIONS.PRE_PROCESS_AUCTION,
            params: ({
              event: {
                data: { auctionId },
              },
            }) => auctionId,
          },
        ],
      },
      {
        actions: [
          ACTIONS.UPDATE_AD_MANAGER_TARGETING,
          sendTo(
            'adManager',
            ({
              event: {
                data: { auctionId },
              },
            }) => ({
              type: AD_MANAGER_EVENTS_IN.AUCTION_PROCESSED,
              data: {
                auctionId,
              },
            }),
          ),
        ],
      },
    ],
    [EVENTS.AUCTION_PROCESSED]: {
      actions: [
        raise(
          ({
            event: {
              data: { auctionId },
            },
          }) => ({
            type: REPORT_AUCTION.END,
            data: {
              time: timeData(),
              auction: auctionId,
            },
          }),
        ),
        sendTo(
          'adManager',
          ({
            event: {
              data: { auctionId },
            },
          }) => ({
            type: AD_MANAGER_EVENTS_IN.AUCTION_PROCESSED,
            data: {
              auctionId,
            },
          }),
        ),
      ],
    },
  },
});

export default bordeauxMachine;
