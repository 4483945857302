import { Ad, AdUnitStatus, DataObject, BordeauxMachineContext } from '@repo/shared-types';
import { BordeauxConfig } from '@repo/shared-types/src/zod-schemas';

const requestCompleted = (ad: DataObject<Ad>): boolean => {
  const adStatus = ad.getProperty('status');
  return (
    adStatus === AdUnitStatus.DELIVERED ||
    adStatus === AdUnitStatus.UNDELIVERED ||
    adStatus === AdUnitStatus.INVALID
  );
};
const extractLineItem = (ad: DataObject<Ad>): number => {
  const adGPTOutput = ad.getProperty('gptOutput');
  return adGPTOutput?.lineItem || -1;
};
const lineItemsMatch = (ads: Array<DataObject<Ad>>, config: BordeauxConfig): boolean => {
  const lineItems = ads.map(extractLineItem);
  const firstLineItem = lineItems[0];
  if (firstLineItem === -1) {
    return false;
  }
  return (
    ((config.features.customActivations.FULL_PAGE_TAKEOVER.LINE_ITEM ?? []).length > 0 &&
      lineItems.every(
        (item: number): boolean =>
          item === firstLineItem &&
          (config.features.customActivations.FULL_PAGE_TAKEOVER.LINE_ITEM ?? []).includes(item),
      )) ??
    false
  );
};

const getRoadblockStatus = (context: BordeauxMachineContext): boolean | null => {
  if (context.queryParameters.forceRoadblock !== null) {
    return context.queryParameters.forceRoadblock === 'true';
  }

  const { ads, config } = context;
  const roadblockMatchingAds = ads.getValues().filter(ad => ad.getProperty('inRoadblock'));

  if (roadblockMatchingAds.length === 0) {
    return false;
  }

  const allRequestsComplete = roadblockMatchingAds.every(requestCompleted);
  if (allRequestsComplete) {
    return lineItemsMatch(roadblockMatchingAds, config);
  }

  return null;
};

export default getRoadblockStatus;
